@use "utils" as *;

$color_type_0: rgb(33, 33, 33);
$color_type_1: rgb(45, 60, 31);
$color_type_2: white;

$font_type_0: 500 40px/1.38 "Lato", Helvetica, Arial, serif;

.section2 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include lg {
            width: 80.29%;
      }

      @include md {
            width: 84.45%;
      }

      @include sm {
            width: 87.86%;
            margin: 50px auto 50px;
      }

      @include xs {
            width: 90.61%;
            margin: 46px auto 50px;
      }

      @include xxs {
            width: 92.79%;
      }

      @include tn {
            width: 94.49%;
      }

      @include flex-column;
      width: 75.33%;
      position: relative;
      flex-grow: 1;
      margin: 74px auto 50px;
}
.flexColBottom{
      @include xxl {
            margin-bottom: 171px;
      }

      @include xl {
            margin-bottom: 171px;
      }
      @include lg {
            width: 80.29%;
            margin-bottom: 118px;
      }

      @include md {
            width: 84.45%;
      }

      @include sm {
            width: 87.86%;
            margin: 50px auto 50px;
            margin-bottom: 118px;
      }

      @include xs {
            width: 90.61%;
            margin: 46px auto 50px;
            margin-bottom: 37px;
      }

      @include xxs {
            width: 92.79%;
            margin-bottom: 25px;
      }

      @include tn {
            width: 94.49%;
      }

      @include flex-column;
      width: 75.33%;
      position: relative;
      flex-grow: 1;
      margin: 74px auto 299px;
}

.flexCol1 {
      // @include xl {
      //       width: 34.89%;
      //       margin: 0px 65.11% 0px 0%;
      // }

      // @include lg {
      //       width: 36.03%;
      //       margin: 0px 63.97% 0px 0%;
      // }

      // @include md {
      //       width: 39.1%;
      //       margin: 0px 60.9% 0px 0%;
      // }

      // @include xs {
      //       width: 44.28%;
      //       margin: 0px 55.72% 0px 0%;
      // }

      // @include xxs {
      //       width: 49.38%;
      //       margin: 0px 50.62% 0px 0%;
      // }

      // @include tn {
      //       width: 53.41%;
      //       margin: 0px 46.59% 0px 0%;
      // }

      @include flex-column;
      // width: 32.18%;
      position: relative;
      // margin: 0px 71.82% 0px 0%;
      display: flex;
      flex-direction: row;
}

.flexRow {
      @include v-center-content;
      position: relative;
      width: 50%;
      padding-top: 10px;
      margin-left: 1%;
}

.line {
      background-color: $color_type_1;
      height: 1px;
      position: relative;
      flex: 0 1 76px;
      margin: 13px 0px;
}

.flexRow__cell {
      @include flex-column;
      position: relative;
      flex: 0 1 32px;
}

.image3 {
      @include lg {
            margin: 0px 0px 0px 4px;
      }

      @include tn {
            width: 20px;
            min-width: 20px;
      }

      width: 24px;
      height: auto;
      aspect-ratio: 0.89;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 24px;
      margin: 0px 0px 0px 8px;
}

.hero_title {
      @include lg {
            font-size: 36px;
            text-align: left;
      }

      @include md {
            font-size: 28px;
      }

      @include sm {
            font-size: 24px;
      }

      @include xs {
            font-size: 22px;
      }

      @include tn {
            font-size: 20px;
      }

      @include font-face($font_type_0, $color_type_0, 0px);
      position: relative;
      margin: 16px 0px 0px;
}

.hero_title2 {
      @include lg {
            font-size: 20px !important;
            text-align: left;
      }

      @include md {
            font-size: 18px !important;
      }

      @include sm {
            font-size: 18px !important;
      }

      @include xs {
            font-size: 18px !important;
      }

      @include tn {
            font-size: 15px !important;
      }

      font-size: 25px !important;

      @include font-face($font_type_0, $color_type_0, 0px);
      position: relative;
}

.hero_title3 {
      @include lg {
            font-size: 20px !important;
      }

      @include md {
            font-size: 18px !important;
      }

      @include sm {
            font-size: 18px !important;
      }

      @include xs {
            font-size: 18px !important;
      }

      @include tn {
            font-size: 15px !important;
      }

      font-size: 18px !important;

      @include font-face($font_type_0, $color_type_0, 0px);
      position: relative;
      text-decoration: underline;
      text-underline-offset: 5px;
      text-align: center;
      padding-top: 2%;

}

.flexRow1 {
      @include xxl{
            column-gap: 30px;
      }
      @include lg {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
      }

      @include sm {
            margin: 15px 0px 0px 2px;
      }

      @include xs {
            margin: 10px 0px 0px 2px;
      }

      display: flex;
      align-items: center;
      column-gap: 40px;
      position: relative;
      margin: 20px 0px 0px 2px;
}

.flexRow1__cell {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}

.flexRow1__cell2 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      cursor: pointer !important;
      flex: 1 1 369px;
}

.image1 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.73;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow1__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}

.image2 {
      @include lg {
            margin: 30px 0px 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 1.72;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.image11 {
      @include lg {
            margin: 30px 0px 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 1.73;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow2 {
      @include xxl{
            column-gap: 30px;
      }
      @include lg {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
            margin: 22px 0px 0px 2px;
      }

      display: flex;
      align-items: center;
      column-gap: 40px;
      position: relative;
      margin: 30px 0px 0px 2px;
}

.flexRow2__cell {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}

.flexRow2__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}

.flexRow3 {
      @include lg {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      display: flex;
      align-items: center;
      column-gap: 14px;
      position: relative;
      margin: 30px 0px 0px 2px;
}

.flexRow3__cell {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}

.flexRow3__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}

.flexRow4 {
      @include lg {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      display: flex;
      align-items: center;
      column-gap: 14px;
      position: relative;
      margin: 20px 0px 0px 2px;
}

.flexRow4__cell {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}

.flexRow4__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}

.flexRow4__cell2 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}

.flexRow4__cell3 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 369px;
}


.view_more {
      @include font-face($font_type_0, $color_type_1, 0px);
      margin: 5% 0px 0px;
      text-decoration: underline;
      text-underline-offset: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 18px;
}



.ArrowUp {
      padding-left: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      // margin-top: 100px;
      transition: .3s;
      cursor: pointer;
      // margin-bottom: 80px;
}

.ArrowUp:hover {
      transform: scale(1.2);
}


.scroll2top:hover {
      transform: scale(1.2) !important;
      transition: .3s;
}