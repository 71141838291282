@use "utils" as *;

$color_type_0: rgb(133, 133, 133);
$color_type_1: rgb(91, 92, 57);
$color_type_2: rgb(92, 92, 92);

$border_type_0: 2px solid rgb(133, 133, 133);

$border_radius_type_0: 52px 52px 52px 52px;

$font_type_0: italic 600 48px/1 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.71 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: 400 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: italic 600 1.1875em/1.33 "Playfair Display", Helvetica, Arial, serif;
$font_type_4: 600 1.1875em/1.33 "Playfair Display", Helvetica, Arial, serif;
$font_type_5: 0.22916666666666666em/5.9 "Playfair Display", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include xl {
    width: 66.63%;
  }
  @include lg {
    margin: 244px auto 171px;
  }
  @include md {
    width: 75.97%;
    margin: 212px auto 131px;
  }
  @include sm {
    width: 80.83%;
    margin: 173px auto 131px;
  }
  @include xs {
    width: 84.9%;
    margin: 142px auto 131px;
  }
  @include xxs {
    width: 88.23%;
  }
  @include tn {
    width: 90.9%;
    margin: 115px auto 97px;
  }
  @include flex-column;
  width: 56.78%;
  position: relative;
  flex-grow: 1;
  margin: 287px auto 175px;
}
.flexCol1 {
  @include sm;
  @include xxs {
    width: 99.32%;
    margin: 0px 0.68% 0px 0%;
  }
  @include tn {
    width: 99.49%;
    margin: 0px 0.51% 0px 0%;
  }
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 0px 0px;
}
.hero_title {
  @include font-face($font_type_0, $color_type_1, 0px);
  @include xxl {
    text-align: center;
  }
  @include xl {
    font-size: 46px;
  }
  @include lg {
    font-size: 42px;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 30px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 28px;
  }
  @include tn {
    font-size: 24px;
  }
}
.hero_title_box {
  @include xxl {
    align-items: flex-start;
    justify-content: center;
  }
  @include xl {
    align-items: flex-start;
    justify-content: center;
  }
  @include lg {
    align-items: flex-start;
    justify-content: center;
  }
  @include md {
    align-items: flex-start;
    justify-content: center;
  }
  @include sm {
    align-items: flex-start;
    justify-content: center;
  }
  @include xs {
    align-items: flex-start;
    justify-content: center;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: center;
    min-height: 60px;
    margin: 0px;
  }
  @include tn {
    min-height: 85px;
  }
  display: flex;
  position: relative;
  min-height: 83px;
}
.hero_title_span0 {
  font: $font_type_3;
  color: $color_type_1;
  font-style: italic;
  letter-spacing: 0px;
}
.hero_title_span1 {
  font: $font_type_4;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title_span2 {
  font: $font_type_5;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title_span3 {
  font: $font_type_4;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle {
  @include xxl {
    text-align: center;
    justify-content: center;
  }
  @include xl {
    font-size: 18px;
    margin: 0px 105px 0px 90px;
  }
  @include lg {
    font-size: 15px;
    margin: 0px 82px 0px 75px;
  }
  @include md {
    font-size: 14px;
    margin: 14px 52px 0px 44px;
  }
  @include sm {
    font-size: 13px;
    margin: 0px 12px 0px 0px;
  }
  @include xs {
    font-size: 12px;
    margin: 6px 12px 0px 0px;
  }
  @include tn {
    margin: 7px 0px 0px;
  }
  display: flex;
  @include font-face($font_type_2, $color_type_0, 0px);
  position: relative;
  margin: 0px 23px;
}
.content_box {
  @include xl {
    margin: 119px 84px 0px 60px;
  }
  @include lg {
    max-height: 64px;
    margin: 128px 20px 0px 16px;
  }
  @include md {
    max-height: 54px;
    margin: 81px 45px 0px;
  }
  @include sm {
    max-height: 53px;
  }
  @include xs {
    max-height: 50px;
    margin: 62px 15px 0px 23px;
  }
  @include xxs {
    min-height: 33px;
    max-height: 40px;
    margin: 62px 0px 0px;
  }
  @include tn {
    max-height: 51px;
    margin: 56px 0px 0px;
  }
  @include flex-column;
  border: $border_type_0;
  border-radius: $border_radius_type_0;
  outline-style: none;
  outline-width: 0px;
  position: relative;
  min-height: 56px;
  max-height: 69px;
  margin: 97px 99px 0px;
  display: flex;
  justify-content: center;
}
.medium_title {
  @include xxl {
    text-align: center;
    justify-content: center;
  }
  @include md {
    font-size: 16px;
    width: 93.54%;
    margin: 27px 2.42% 26px 4.04%;
  }
  @include sm {
    font-size: 12px;
    width: 95.2%;
    margin: 26px 1.8% 25px 3%;
  }
  @include xs {
    width: 96.06%;
    margin: 26px 1.67% 25px 2.27%;
  }
  @include xxs {
    width: 97.02%;
    margin: 18px 1.26% 25px 1.72%;
  }
  @include tn {
    width: 97.75%;
    margin: 24px 0.95% 25px 1.3%;
  }
  @include xl {
    min-height: 1px;
    margin: 24px auto 26px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_1, $color_type_2, 0px);
  width: 97.96%;
  position: relative;
  flex-grow: 1;
  min-height: 44px;
  max-height: 44px;
//   margin: 12px auto 31px;
}
