@use "utils" as *;

$color_type_0: rgb(114, 124, 87);
$color_type_1: rgb(133, 133, 133);
$color_type_2: rgb(92, 92, 92);
$color_type_3: rgb(252, 252, 252);

$font_type_0: 500 20px/2.5 "Lato", Helvetica, Arial, serif;

.root {
      @include flex-column;
      background-color: $color_type_0;
      position: relative;
      overflow: hidden;

}

.navbar-header {
      background-color: blue !important;
}

.flexRow {
      @include sm {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
            margin: 10px auto;
      }

      @include xs {
            margin: 20px auto;
      }

      @include tn {
            align-items: center;
            margin: 18px auto;
      }

      display: flex;
      width: 88.01%;
      position: relative;
      flex-grow: 1;
      margin: 25px auto;
}

.flexRow__item {
      @include sm {
            flex: 0 0 73px;
            min-width: 73px;
      }

      @include xs {
            flex: 0 0 63px;
            min-width: 63px;
      }

      @include tn {
            flex: 0 0 50px;
            min-width: 50px;
      }

      @include flex-column;
      position: relative;
      flex: 0 0 78px;
      min-width: 78px;
}

.image {
      width: 70px;
      height: auto;
      aspect-ratio: 1.02;
      vertical-align: top;
      object-fit: contain;
      object-position: center center;
      position: relative;
      margin: 10px 0px 10px 20px;
      cursor: pointer;

}

.flexRow__spacer {
      @include sm {
            flex: 0 0 407px;
            min-width: 407px;
      }

      @include xs {
            flex: 0 0 324px;
            min-width: 324px;
      }

      @include xxs {
            flex: 0 0 245px;
            min-width: 245px;
      }

      @include tn {
            flex: 0 0 210px;
            min-width: 210px;
      }

      @include flex-column;
      position: relative;
      flex: 0 0 642px;
      min-width: 642px;
}

.flexRow__item1 {
      @include flex-column;
      position: relative;
      flex: 0 0 32px;
      min-width: 32px;
}

.image1 {
      @include sm {
            width: 28px;
            min-width: 28px;
      }

      @include tn {
            width: 21px;
            min-width: 21px;
      }

      width: 32px;
      height: auto;
      aspect-ratio: 1.28;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 32px;
      margin: 24px 0px 16px;
}

th {
      @include font-face($font_type_0, $color_type_2, 0px);
      cursor: pointer;
}
.item_style{
      @include font-face($font_type_0, $color_type_2, 0px);
      cursor: pointer;
      display: inline-block;
      height: 100%;
}

.language_img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
}
.language_text{
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #5C5C5C;
margin-left: 3px;
}

.language_box{
      display: flex;
      align-items: center;
      height: 52px;
}