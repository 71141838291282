@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$font_type_0: 700 70px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: italic 700 25px/1.24 "Lato", Helvetica, Arial, serif;
$font_type_2: 400 20px/1.55 "Lato", Helvetica, Arial, serif;

.section1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include xxl {
            width: 92.48%;
      }

      @include xl {
            width: 94.25%;
      }

      @include lg {
            width: 95.62%;
            margin: 284px auto 57px;
      }

      @include md {
            width: 96.68%;
      }

      @include sm {
            width: 97.49%;
            margin: 262px auto 57px;
      }

      @include xs {
            width: 98.11%;
            margin: 223px auto 57px;
      }

      @include xxs {
            width: 98.57%;
      }

      @include tn {
            width: 98.93%;
            margin: 223px auto 34px;
      }

      @include flex-column;
      width: 90.21%;
      position: relative;
      flex-grow: 1;
      margin: 347px auto 57px;
}

.flexRow {
      @include sm {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      width: 84.97%;
      position: relative;
      margin: 0px 6.09% 0px 8.94%;
}

.flexRow__cell {
      @include sm {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 1;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 0 207px;
      min-width: 207px;
}

.medium_title {
      @include lg {
            font-size: 20px;
            text-align: left;
            margin: 210px 0px 125px;
      }

      @include sm {
            font-size: 16px;
            margin: 0px 0px 6px;
      }

      @include font-face($font_type_1, $color_type_1, 0px);
      position: relative;
      // flex-grow: 1;
      margin: 259px 0px 125px;
}

.flexRow__spacer {
      @include sm {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 161px;
}

.flexRow__cell1 {
      @include sm {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 3;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 791px;
}

.flexCol1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.hero_title {
      @include lg {
            font-size: 50px;
            text-align: left;
      }

      @include md {
            font-size: 52px;
      }

      @include sm {
            font-size: 40px;
      }

      @include xs {
            font-size: 36px;
      }

      @include xxs {
            font-size: 32px;
      }

      @include font-face($font_type_0, $color_type_0, 0px);
      position: relative;
}

.subtitle {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xxs {
            font-size: 12px;
      }

      @include xs {
            margin: 29px 0px 0px 3px;
      }

      @include font-face($font_type_2, $color_type_1, 0px);
      position: relative;
      margin: 39px 0px 0px 3px;
}
.subtitle_text{
      line-height: 1.5;
}
.subtitle_text2{
      line-height: 1.5;
      margin: 10px 0;
}

.flexCol__cell {
      @include flex-column;
      position: relative;
}

.image2 {
      @include lg {
            margin: 58px 0px 0px;
      }

      @include sm {
            width: calc(100% - 40px);
            margin: 58px 20px 0px;
      }

      @include tn {
            margin: 22px auto 0px;
            width: 94.78%;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 2.2;
      transition-duration: 0.3s;
      transition-property: transform;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 84px 0px 0px;
}