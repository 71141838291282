@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$border_radius_type_0: 26.5px 26.5px 26.5px 26.5px;

$font_type_0: 600 60px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 700 30px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: 400 20px/1.55 "Lato", Helvetica, Arial, serif;
$font_type_3: 600 20px/1.55 "Lato", Helvetica, Arial, serif;
$font_type_4: 400 20px/1.4 "Lato", Helvetica, Arial, serif;
$font_type_5: 400 20px/1.4 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_6: 1em/1.4 "Lato", Helvetica, Arial, serif;
$font_type_7: 700 1em/1.4 "Lato", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
// 旧版
/* .flexCol {
  @include xl {
    margin: 330px 11.18% 198px 12.76%;
  }
  @include md {
    margin: 261px 11.18% 198px 12.76%;
  }
  @include sm {
    margin: 203px 11.18% 157px 12.76%;
  }
  @include xs {
    width: 77.28%;
    margin: 203px auto 114px;
  }
  @include xxs {
    margin: 203px auto 111px;
  }
  @include tn {
    margin: 144px auto 111px;
  }
  @include flex-column;
  width: 76.06%;
  position: relative;
  flex-grow: 1;
  margin: 355px 11.18% 264px 12.76%;
} */

.flexCol {

  @include xxl {
    margin: 330px auto 171px;
}
  @include xl {
    margin: 261px auto 171px;
}
  @include lg {
    margin: 231px auto 118px;
}

@include md {
    margin: 201px auto 118px;
}

@include sm {
    margin: 172px auto 118px;
}
@include xs {
  margin-bottom: 37px;
}
@include tn {
    margin: 144px auto 25px;
}

@include flex-column;
width: 90.28%;
position: relative;
flex-grow: 1;
margin: 330px auto 299px;
}
.flexRow {
  @include md {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 0px 0px 0px 2px;
}
.flexRow__cell {
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 291px;
}
.hero_title {
  @include xl {
    font-size: 55px;
    text-align: left;
  }
  @include lg {
    font-size: 52px;
  }
  @include md {
    font-size: 42px;
  }
  @include sm {
    font-size: 30px;
  }
  @include xs {
    font-size: 28px;
  }
  @include xxs {
    font-size: 26px;
  }
  @include font-face($font_type_0, $color_type_0, 0px);
  position: relative;
  // flex-grow: 1;
  white-space: nowrap;
}
.flexRow__spacer {
  @include md {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 198px;
}
.flexRow__cell1 {
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 659px;
}
.subtitle {
  @include lg {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
  @include xs {
    font-size: 12px;
  }
  @include md {
    margin: 0px 0px 21px;
  }
  @include font-face($font_type_2, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 20px 0px 21px;
}
.flexCol1 {
  @include xxl {
    margin: 180px 15px 0px 0px;
  }
  @include xl {
    margin: 160px 15px 0px 0px;
  }
  @include lg {
    margin: 140px 15px 0px 0px;
  }
  @include md {
    margin: 103px 8px 0px 0px;
  }
  @include sm {
    margin: 70px 8px 0px 0px;
  }
  @include tn {
    margin: 70px 4px 0px 0px;
  }
  @include flex-column;
  position: relative;
  margin: 206px 15px 0px 0px;
}
.flexRow1 {
  @include lg {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px 0px 0px 2px;
}
.flexRow1__cell {
  @include lg {
    flex: 0 0 347px;
    order: 1;
    min-width: unset;
  }
  @include md {
    flex: 0 0 572px;
  }
  @include sm {
    flex: 0 0 427px;
  }
  @include xs {
    flex: 0 0 355px;
  }
  @include xxs {
    flex: 0 0 280px;
  }
  @include tn {
    flex: 0 0 236px;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 489px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.title {
  @include lg {
    font-size: 24px;
    text-align: left;
  }
  @include sm {
    font-size: 20px;
  }
  @include xxs {
    font-size: 16px;
  }
  @include font-face($font_type_1, $color_type_0, 0px);
  position: relative;
}
.subtitle1 {
  @include font-face($font_type_4, $color_type_1, 0px);
  @include xl {
    font-size: 18px;
    text-align: left;
  }
  @include lg {
    font-size: 16px;
  }
  @include sm {
    font-size: 12px;
  }
}
.subtitle1_box {
  @include xl {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include lg {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include md {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  position: relative;
  margin: 13px 0px 0px;
}
.subtitle1_span0 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle1_span1 {
  font: $font_type_7;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle1_span2 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle1_span3 {
  font: $font_type_7;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle1_span4 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow1__spacer {
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 1 1 calc(100% - 572px);
    margin-left: 0px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 1 1 calc(100% - 427px);
  }
  @include xs {
    flex: 1 1 calc(100% - 355px);
  }
  @include xxs {
    flex: 1 1 calc(100% - 280px);
  }
  @include tn {
    flex: 1 1 calc(100% - 236px);
  }
  @include flex-column;
  position: relative;
  flex: 0 1 101px;
}
.flexRow1__cell1 {
  @include lg {
    flex: 0 0 calc(1 / 1 * 100% - 347px / 1);
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 3;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 543px;
}
.image1 {
  @include xl {
    margin: 36px 0px 3px;
  }
  @include lg {
    width: calc(100% - 35px);
    margin: 52px 0px 3px 35px;
  }
  @include md {
    margin: 5px 0px 3px;
  }
  width: 100%;
  height: auto;
  aspect-ratio: 1.04;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  margin: 55px 0px 3px;
}
.image1:hover {
      transform: scale(1.1);
    }
.content_box {
  @include flex-column;
  border-radius: $border_radius_type_0;
  border:1px solid rgb(92, 92, 92);
  outline: 1px none rgb(92, 92, 92);
  outline-offset: -1px;
  width: 100%;
  height: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
}
.content_box:hover {
      transform: translateX(-8px);
      background-color: #5B5C39;
      .image2{
      filter: invert(500%);
      filter: brightness(10);
      }
      .subtitle3 {
            color: white;
          }
    }
.flexRow2 {
  @include xl {
    justify-content: flex-start;
    align-items: center;
    column-gap: 0px;
  }
  @include lg {
    margin: 6px 15px 7px;
  }
  @include md {
    margin: 6px 15px 7px 16px;
  }
  @include sm {
    margin: 6px 12px 7px;
  }
  @include xs {
    margin: 2px 12px 7px 14px;
  }
  @include xxs {
    margin: 2px 8px 7px 14px;
  }
  @include tn {
    margin: 2px 4px 7px 12px;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-grow: 1;
  margin: 12px 22px 7px 23px;
}
.flexRow2__cell {
  @include xl {
    margin-right: 10px;
  }
  @include lg {
    margin-right: 8px;
  }
  @include sm {
    margin-right: 6px;
  }
  @include xs {
    margin-right: 4px;
  }
  @include flex-column;
  position: relative;
  margin-right: 12px;
  white-space: nowrap;
}
.subtitle3 {
  @include xl {
    font-size: 18px;
    text-align: left;
  }
  @include lg {
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
  }
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
}
.flexRow2__cell1 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
}
.image2 {
  @include md {
    margin: 13px 0px 13px 4px;
  }
  @include xxs {
    margin: 13px 0px;
  }
  @include tn {
    margin: 13px 0px 13px 4px;
  }
  width: 31px;
  height: auto;
  aspect-ratio: 3.88;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 31px;
  margin: 13px 0px 13px 9px;
}
.flexRow3 {
  @include xl {
    margin: 96px 2px 0px;
  }
  @include lg {
    justify-content: flex-start;
    align-items: center;
    column-gap: 0px;
    margin: 86px 2px 0px;
  }
  @include md {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 134px 2px 0px;
}
.flexRow3__cell {
  @include lg {
    flex: 1 1 465px;
    min-width: unset;
  }
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 3;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 543px;
}
.image11 {
  @include lg {
    margin: 0px;
  }
  width: 100%;
  height: auto;
  aspect-ratio: 1.04;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image11:hover {
      transform: scale(1.1);
    }
.flexRow3__spacer {
  @include md {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 75px;
}
.flexRow3__cell1 {
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 1;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 507px;
}
.flexCol3 {
  @include md {
    margin: 0px 0px 7px;
  }
  @include tn {
    margin: 0px;
  }
  @include flex-column;
  position: relative;
  // flex-grow: 1;
  margin: 0px 0px 26px;
}
.subtitle2 {
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  @include sm {
    font-size: 12px;
    margin: 9px 0px 0px;
  }
  @include font-face($font_type_5, $color_type_1, 0px);
  position: relative;
  margin: 15px 0px 0px;
}
