@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(114, 124, 87);
$color_type_2: rgb(133, 133, 133);
$color_type_3: rgb(45, 60, 31);
$color_type_4: rgb(91, 92, 57);
$color_type_5: rgb(92, 92, 92);

$font_type_0: 700 60px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 600 26px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: italic 700 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: 700 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_4: 700 18px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_5: 400 18px/1.38 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_6: 600 1em/1.38 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_7: 1em/1.38 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_8: 700 1em/1.38 "Source Sans Pro", Helvetica, Arial, serif;

.section1 {
  @include tn {
    margin: 0px 23px 0px 12px;
  }
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include xl {
    margin: 290px 9.39% 219px 5.03%;
  }
  @include lg {
    margin: 290px 9.39% 171px 5.03%;
  }
  @include xs {
    margin: 290px 9.39% 147px 5.03%;
  }
  @include xxs {
    margin: 180px 9.39% 125px 5.03%;
  }
  @include tn {
    margin: 180px 9.39% 82px 5.03%;
  }
  @include flex-column;
  width: 85.58%;
  position: relative;
  flex-grow: 1;
  margin: 290px 9.39% 202px 5.03%;
}
.flexCol1 {
  @include xl {
    width: 65.7%;
    margin: 0px 34.3% 0px 0%;
  }
  @include md {
    width: 100%;
    margin: 0px 0px;
  }
  @include flex-column;
  width: 50.95%;
  position: relative;
  margin: 0px 49.05% 0px 0%;
}
.flexCol2 {
  @include flex-column;
  position: relative;
}
.hero_title {
  @include lg {
    font-size: 52px;
    text-align: left;
  }
  @include md {
    font-size: 44px;
  }
  @include sm {
    font-size: 40px;
    min-height: 67px;
    margin: 0px;
  }
  @include xs {
    font-size: 34px;
    min-height: 58px;
  }
  @include xxs {
    font-size: 32px;
    min-height: 52px;
  }
  @include tn {
    font-size: 25px;
    min-height: 30px;
  }
  @include font-face($font_type_0, $color_type_3, 0px);
  position: relative;
  min-height: 83px;
}
.subtitle {
  @include lg {
    font-size: 16px;
    text-align: left;
    margin: 0px 5px;
  }
  @include xl {
    margin: 10px 5px 0px;
  }
  @include font-face($font_type_2, $color_type_2, 0px);
  position: relative;
  margin: 12px 5px 0px;
}
.subtitle1 {
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  @include tn {
    font-size: 12px;
  }
  @include font-face($font_type_3, $color_type_5, 0px);
  position: relative;
  min-height: 168px;
  margin: 50px 1px 0px 0px;
}
.flexCol3 {
  @include xl {
    margin: 65px 0px 0px;
  }
  @include flex-column;
  position: relative;
  margin: 91px 0px 0px;
}
.medium_title {
  @include lg {
    font-size: 20px;
    text-align: left;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
}
.flexRow {
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  @include sm {
    row-gap: 2px;
    align-items: stretch;
    margin: 8px 0px 0px;
  }
  display: flex;
  position: relative;
  margin: 20px 0px 0px;
}
.flexRow__item {
  @include xl {
    flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
  }
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
  }
  @include flex-column;
  position: relative;
  flex: 1 1 592px;
}
.highlights1 {
  @include font-face($font_type_4, $color_type_0, 0px);
  @include xl {
    text-align: left;
  }
  @include sm {
    font-size: 12px;
  }
}
.highlights1_box {
  @include xl {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  width: 100%;
  height: 100%;
}
.highlights1_span0 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span1 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span2 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span3 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span4 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span5 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span6 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span7 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span8 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span9 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span10 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights1_span11 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow__spacer {
  @include xl {
    flex: 0 0 16px;
  }
  @include md {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 110px;
}
.highlights2 {
  @include font-face($font_type_4, $color_type_5, 0px);
  @include sm {
    font-size: 12px;
    text-align: left;
  }
}
.highlights2_box {
  @include xl;
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  width: 100%;
  height: 100%;
}
.highlights2_span0 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span1 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span2 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span3 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span4 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span5 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span6 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span7 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span8 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span9 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span10 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span11 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span12 {
  font: $font_type_7;
  color: $color_type_5;
  text-decoration: underline;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span13 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span14 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span15 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span16 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span17 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span18 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.flexCol4 {
  @include xl {
    margin: 60px 0px 0px;
  }
  @include sm {
    margin: 0px;
  }
  @include flex-column;
  position: relative;
  margin: 49px 0px 0px;
}
.medium_title1 {
  @include lg {
    font-size: 20px;
    text-align: left;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
    margin: 42px 0px 0px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
}
.flexRow1 {
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  @include sm {
    margin: 11px 0px 0px;
  }
  @include tn {
    margin: 5px 0px 0px;
  }
  display: flex;
  position: relative;
  margin: 21px 0px 0px;
}
.flexRow1__item {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
  }
  @include flex-column;
  position: relative;
  flex: 1 1 592px;
}
.highlights2_span19 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span20 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span21 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span22 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span23 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span24 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span25 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span26 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span27 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span28 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span29 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span30 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span31 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span32 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span33 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span34 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span35 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span36 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span37 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span38 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span39 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span40 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span41 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span42 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span43 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span44 {
  font: $font_type_8;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights2_span45 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow1__spacer {
  @include xl {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 110px;
}
.flexCol5 {
  @include xl {
    margin: 0px;
  }
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.highlights11 {
  @include font-face($font_type_4, $color_type_0, 0px);
  @include sm {
    font-size: 12px;
    text-align: left;
  }
}
.highlights11_box {
  @include xl;
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  width: 100%;
  height: 100%;
}
.highlights11_span0 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span1 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span2 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span3 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span4 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span5 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span6 {
  font: $font_type_6;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span7 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span8 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span9 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span10 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span11 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span12 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span13 {
  font: $font_type_6;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights11_span14 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.medium_title2 {
  @include lg {
    font-size: 20px;
    text-align: left;
    margin: 45px 0px 0px;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 82px 0px 0px;
}
.highlights3 {
  @include sm {
    font-size: 12px;
    text-align: left;
    margin: 5px 0px 0px;
  }
  @include xl {
    min-height: 148px;
  }
  @include font-face($font_type_5, $color_type_5, 0px);
  position: relative;
  min-height: 235px;
  margin: 16px 0px 0px;
}
.medium_title3 {
  @include lg {
    font-size: 20px;
    text-align: left;
    margin: 51px 0px 0px;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 65px 0px 0px;
}
.highlights31 {
  @include sm {
    font-size: 12px;
    text-align: left;
    margin: 5px 0px 0px;
  }
  @include xl {
    min-height: 61px;
  }
  @include font-face($font_type_5, $color_type_5, 0px);
  position: relative;
  min-height: 125px;
  margin: 16px 0px 0px;
}
.medium_title4 {
  @include lg {
    font-size: 20px;
    text-align: left;
    margin: 49px 0px 0px;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 63px 0px 0px;
}
.highlights32 {
  @include sm {
    font-size: 12px;
    text-align: left;
    margin: 7px 0px 0px;
  }
  @include font-face($font_type_5, $color_type_5, 0px);
  position: relative;
  margin: 16px 0px 0px;
}
.medium_title5 {
  @include lg {
    font-size: 20px;
    text-align: left;
    margin: 48px 0px 0px;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 25px;
  }
  @include xs {
    font-size: 23px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 17px;
  }
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 64px 0px 0px;
}
.highlights33 {
  @include font-face($font_type_5, $color_type_5, 0px);
  @include sm {
    font-size: 12px;
    text-align: left;
  }
}
.highlights33_box {
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 4px 0px 0px;
  }
  position: relative;
  min-height: 102px;
  margin: 16px 0px 0px;
}
.highlights33_span0 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights33_span1 {
  font: $font_type_7;
  color: $color_type_5;
  text-decoration: underline;
  font-style: normal;
  letter-spacing: 0px;
}
.highlights33_span2 {
  font: $font_type_7;
  color: $color_type_5;
  font-style: normal;
  letter-spacing: 0px;
}
