@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$font_type_0: italic 500 32px/1.65 "Montserrat", Helvetica, Arial, serif;
$font_type_1: 400 20px/1.55 "Lato", Helvetica, Arial, serif;
$font_type_2: 1em/1.55 "Lato", Helvetica, Arial, serif;
$font_type_3: 600 1em/1.55 "Lato", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexRow {
  @include lg {
    width: 81.13%;
  }
  @include md {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
    width: 85.15%;
    margin: 35px auto 83px;
  }
  @include sm {
    width: 88.43%;
    margin: 0px auto 83px;
  }
  @include xs {
    width: 91.06%;
  }
  @include xxs {
    width: 93.14%;
  }
  @include tn {
    width: 94.77%;
  }
  display: flex;
  align-items: center;
  width: 76.32%;
  position: relative;
  flex-grow: 1;
  margin: 99px auto 83px;
}
.flexRow__cell {
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 471px;
}
.flexCol {
  @include md {
    margin: 0px 0px 22px;
  }
  @include tn {
    margin: 0px;
  }
  @include flex-column;
  position: relative;
  // flex-grow: 1;
  margin: 40px 0px 48px;
}
.title {
  @include xl {
    font-size: 27px;
    text-align: left;
  }
  @include lg {
    font-size: 28px;
  }
  @include md {
    font-size: 22px;
    text-align: center;
    justify-content: center;
  }
  @include sm {
    font-size: 20px;
  }
  @include xxs {
    font-size: 16px;
  }
  display: flex;
  @include font-face($font_type_0, $color_type_0, 0px);
  position: relative;
}
.subtitle {
  @include font-face($font_type_1, $color_type_1, 0px);
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  @include md {
    text-align: center;
  }
  @include xxs {
    font-size: 12px;
  }
}
.subtitle_box {
  @include lg {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include md {
    align-items: flex-start;
    justify-content: center;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: center;
    margin: 14px 0px 0px;
  }
  display: flex;
  position: relative;
  margin: 20px 0px 0px;
}
.subtitle_span0 {
  font: $font_type_2;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.subtitle_span1 {
  font: $font_type_3;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow__spacer {
  @include md {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 89px;
}
.flexRow__cell1 {
  @include md {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 585px;
}
.image {
  @include tn {
    margin: 9px 0px 0px;
  }
  width: 100%;
  height: auto;
  aspect-ratio: 1.96;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image:hover {
  transform: translateX(8px);
}
