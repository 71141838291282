@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$font_type_0: italic 500 65px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: italic 600 65px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: italic 500 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: italic 500 1em/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_4: 600 0.9em/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_5: italic 600 0.9em/1.38 "Playfair Display", Helvetica, Arial, serif;

.section1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include lg {
            width: 80.22%;
            margin: 253px auto 81px;
      }

      @include md {
            width: 84.4%;
      }

      @include sm {
            width: 87.82%;
      }

      @include xs {
            width: 90.58%;
            margin: 168px auto 81px;
      }

      @include xxs {
            width: 92.76%;
            margin: 128px auto 51px;
      }

      @include tn {
            width: 94.47%;
      }

      @include flex-column;
      width: 75.26%;
      position: relative;
      flex-grow: 1;
      margin: 403px auto 81px;
}

.flexCol1 {
      @include flex-column;
      position: relative;
      margin: 0px 0px 0px 3px;
}

.flexCol1__cell {
      @include flex-column;
      position: relative;
      z-index: 1;
}

.flexRow {
      @include md {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
}

.flexRow__cell {
      @include lg {
            margin-right: 80px;
      }
      @include md {
            margin-right: 60px;
      }

      @include sm {
            margin-right: 40px;
      }

      @include xxs {
            margin-right: 20px;
      }

      @include tn {
            margin-right: 20px;
      }

      @include flex-column;
      position: relative;
      margin-right: 100px;
}

.hero_title1 {
      @include font-face($font_type_0, $color_type_0, 0px);

      @include lg {
            font-size: 56px;
            text-align: left;
      }

      @include md {
            font-size: 48px;
      }

      @include sm {
            font-size: 40px;
      }

      @include xs {
            font-size: 36px;
      }

      @include xxs {
            font-size: 30px;
      }

      @include tn {
            font-size: 28px;
      }
}

.hero_title1_box {
      @include lg {
            align-items: flex-start;
            justify-content: flex-start;
      }

      @include md {
            align-items: flex-start;
            justify-content: flex-start;
      }

      @include sm {
            align-items: flex-start;
            justify-content: flex-start;
      }

      @include xs {
            align-items: flex-start;
            justify-content: flex-start;
      }

      @include xxs {
            align-items: flex-start;
            justify-content: flex-start;
      }

      @include tn {
            align-items: flex-start;
            justify-content: flex-start;
      }
      position: relative;
      // flex-grow: 1;
      white-space: nowrap;
}

.hero_title1_span0 {
      font: $font_type_3;
      color: $color_type_0;
      font-style: italic;
      letter-spacing: 0px;
}

.hero_title1_span1 {
      font: $font_type_4;
      color: $color_type_0;
      font-style: normal;
      letter-spacing: 0px;
}

.flexRow__spacer {
      @include flex-column;
      position: relative;
      flex: 0 1 44px;
}

.flexRow__cell1 {
      @include flex-column;
      position: relative;
      flex: 0 1 218px;
}

.line1 {
      background-color: $color_type_0;
      height: 2px;
      position: relative;
      margin: 51px 0px 37px;
}

.flexCol1__cell1 {
      margin-top: -5px;
      /* @include flex-column;
      position: relative;
      z-index: 0; */
}

.flexRow1 {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      @include sm {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
      flex-grow: 1;
}

.flexRow1__cell {
      @include xl {
            flex: 1 1 79px;
            min-width: unset;
            order: 1;
      }

      @include lg {
            flex: 1 1 663px;
      }

      @include sm {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 3;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 506px;
}

.subtitle {
      @include lg {
            font-size: 14px;
            text-align: left;
      }

      @include md {
            font-size: 12px;
      }

      @include sm {
            text-align: right;
            justify-content: flex-end;
            margin: 0px 0px 14px 212px;
      }

      @include xl {
            margin: 25px 0px 14px;
      }

      @include xxs {
            margin: 0px 0px 14px 134px;
      }

      @include tn {
            margin: 0px 0px 14px;
      }

      @include font-face($font_type_2, $color_type_1, 0px);
      position: relative;
      flex-grow: 1;
      margin: 16px 0px 14px;
}
.inline_block{
      display: inline-block;
}
.flexRow1__spacer {
      @include xl {
            flex: 0 1 auto;
            min-width: unset;
            order: 2;
      }

      @include sm {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 9px;
}

.flexRow1__cell1 {
      @include xl {
            flex: 0 0 430px;
            min-width: 430px;
            order: 3;
      }

      @include lg {
            flex: 0 0 438px;
            min-width: 438px;
      }

      @include md {
            flex: 0 0 349px;
            min-width: 349px;
      }

      @include sm {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 1;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 0 440px;
      min-width: 440px;
}

.hero_title11 {
      @include font-face($font_type_1, $color_type_0, 0px);

      @include xxl {
            text-align: right;
      }

      @include lg {
            font-size: 56px;
      }

      @include md {
            font-size: 48px;
      }

      @include sm {
            font-size: 40px;
      }

      @include xs {
            font-size: 36px;
      }

      @include xxs {
            font-size: 30px;
      }

      @include tn {
            font-size: 28px;
      }
}

.hero_title11_box {
      @include xxl {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include lg {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include md {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include sm {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include xs {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include xxs {
            align-items: flex-start;
            justify-content: flex-end;
      }

      @include tn {
            align-items: flex-start;
            justify-content: flex-end;
      }

      display: flex;
      position: relative;
      flex-grow: 1;
}

.hero_title11_span0 {
      font: $font_type_5;
      color: $color_type_0;
      font-style: italic;
      letter-spacing: 0px;
}

.hero_title11_span1 {
      font: $font_type_4;
      color: $color_type_0;
      font-style: normal;
      letter-spacing: 0px;
}

.flexCol__cell {
      @include flex-column;
      position: relative;
}

.image {
      @include sm {
            margin: 12px 0px 0px;
      }

      @include xxs {
            margin: 0px;
      }

      width: calc(100% - 6px);
      height: auto;
      aspect-ratio: 2.10;
      transition-duration: 0.3s;
      transition-property: transform;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 60px 6px 0px 0px;
}