@use "utils" as *;

$color_type_0: rgb(133, 133, 133);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(91, 92, 57);
$color_type_3: rgb(92, 92, 92);

$border_radius_type_0: 20px;
$border_radius_type_1: 25px;
$border_radius_type_2: 26.5px 26.5px 26.5px 26.5px;

$font_type_0: 600 60px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 700 45px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: italic 700 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_4: 400 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_5: 700 20px/0.9 "Lato", Helvetica, Arial, serif;
$font_type_6: 18px/1.9 "Lato", Helvetica, Arial, serif;
$font_type_7: 400 18px/1.9 "Lato", Helvetica, Arial, serif;

.section1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include lg {
            margin: 213px auto 235px;
      }

      @include sm {
            margin: 213px auto 175px;
      }

      @include xxs {
            width: 85.88%;
            margin: 213px 7.67% 175px 6.45%;
      }

      @include flex-column;
      width: 74.01%;
      position: relative;
      flex-grow: 1;
      margin: 320px auto 235px;
}

.flexRow {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      @include md {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
}

.flexRow__item {
      @include xl {
            flex: 0 1 965px;
            order: 1;
      }

      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      @include flex-column;
      position: relative;
      flex: 0 1 845px;
}

.flexCol1 {
      @include xs {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol1__item {
      @include xs {
            flex: 0 0 24px;
      }

      @include flex-column;
      position: relative;
}

.subtitle {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include xs {
            font-size: 14px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      flex-grow: 1;
      margin-left: 5px;
}

.flexCol1__item1 {
      @include xs {
            flex: 0 0 auto;
      }

      @include flex-column;
      position: relative;
}

.hero_title {
      @include xl {
            font-size: 55px;
            text-align: left;
      }

      @include lg {
            font-size: 42px;
      }

      @include sm {
            font-size: 32px;
      }

      @include xs {
            font-size: 38px;
      }

      @include xxs {
            font-size: 33px;
      }

      @include font-face($font_type_0, $color_type_2, 0px);
      position: relative;
      flex-grow: 1;
}

.flexCol1__item2 {
      @include flex-column;
      position: relative;
}

.subtitle2 {
      @include lg {
            font-size: 18px;
            text-align: left;
      }

      @include sm {
            font-size: 16px;
      }

      @include xs {
            font-size: 14px;
            margin: 10px 5px 0px;
      }

      @include font-face($font_type_3, $color_type_0, 0px);
      position: relative;
      flex-grow: 1;
      margin: 0px 5px;
}

.flexRow__spacer {
      @include xl {
            flex: 0 1 auto;
            order: 2;
      }

      @include md {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 120px;
}

.flexRow__item1 {
      @include xl {
            order: 3;
      }

      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      @include flex-column;
      position: relative;
      flex: 0 1 154px;
}

.flexCol2 {
      @include flex-column;
      position: relative;
      // flex-grow: 1;
      margin: 19px 0px 16px;
}

.subtitle1 {
      @include lg {
            font-size: 16px;
      }

      @include md {
            font-size: 14px;
            text-align: left;
            justify-content: flex-start;
      }

      @include xs {
            font-size: 12px;
      }

      display: flex;
      justify-content: flex-end;
      font: $font_type_2;
      color: $color_type_3;
      text-align: right;
      letter-spacing: 0px;
      position: relative;
}

.highlights3 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include md {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_6, $color_type_3, 0px);
      position: relative;
      margin: 6px 0px 0px;
}

.highlights2 {
      @include lg {
            font-size: 16px;
      }

      @include md {
            font-size: 14px;
            text-align: left;
            justify-content: flex-start;
      }

      @include xs {
            font-size: 12px;
      }

      display: flex;
      justify-content: flex-end;
      font: $font_type_7;
      color: $color_type_3;
      text-align: right;
      letter-spacing: 0px;
      position: relative;
}

.content_box2 {
      @include md {
            margin: 65px 0px 0px;
      }

      @include xxs {
            margin: 13px 0px 0px;
      }

      @include tn {
            margin: 9px 0px 0px;
      }

      @include flex-column;
      border-radius: $border_radius_type_1;
      box-shadow: 0px 0px 1px 1px rgb(92, 92, 92);
      // outline: 1px solid rgb(92, 92, 92);
      outline-offset: -1px;
      position: relative;
      margin: 145px 0px 0px;
}

.flexCol3 {
      @include sm {
            width: 86.68%;
            margin: 34px 7.62% 48px 5.7%;
      }

      @include xs {
            margin: 34px 7.62% 31px 5.7%;
      }

      @include tn {
            width: 92.95%;
            margin: 34px 3.95% 22px 3.1%;
      }

      @include flex-column;
      width: 88.38%;
      position: relative;
      flex-grow: 1;
      margin: 45px 7.15% 48px 4.47%;
}

.hero_title1 {
      @include lg {
            font-size: 36px;
            text-align: left;
      }

      @include md {
            font-size: 32px;
      }

      @include sm {
            font-size: 30px;
      }

      @include xs {
            font-size: 28px;
      }

      @include font-face($font_type_1, $color_type_3, 0px);
      position: relative;
}

.flexRow1 {
      @include v-center-content;
      position: relative;
      margin: 64px 0px 0px 2px;
}

.flexCol4 {
      @include flex-column;
      position: relative;
      flex: 0 1 434px;
}

.subtitle3 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_4, $color_type_3, 0px);
      position: relative;
      margin: 0px 16px 0px 0px;
}

.line1 {
      background-color: $color_type_3;
      height: 1px;
      position: relative;
      margin: 40px 0px 0px;
}

.flexRow1__spacer {
      @include flex-column;
      position: relative;
      flex: 0 1 95px;
}

.flexCol5 {
      @include flex-column;
      position: relative;
      flex: 0 1 458px;
}

.subtitle31 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_4, $color_type_3, 0px);
      position: relative;
      margin: 0px 9px 0px 0px;
}

.line2 {
      background-color: $color_type_3;
      height: 1px;
      position: relative;
      margin: 40px 0px 0px;
}

.flexRow2 {
      @include v-center-content;
      position: relative;
      margin: 66px 0px 0px 2px;
}

.flexRow2__spacer {
      @include flex-column;
      position: relative;
      flex: 0 1 95px;
}

.content_box1 {
      @include sm {
            border-radius: $border_radius_type_0;
      }

      @include flex-column;
      border-radius: $border_radius_type_1;
      box-shadow: 0px 0px 1px 1px rgb(92, 92, 92);
      outline-offset: -1px;
      position: relative;
      margin: 70px 0px 0px 4px;
}

.subtitle32 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_4, $color_type_3, 0px);
      position: relative;
      flex-grow: 1;
      margin: 21px 14px 275px;
}

.content_box11 {
      @include flex-column;
      background-color: $color_type_2;
      border-radius: $border_radius_type_2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
}

.subtitle4 {
      @include lg {
            font-size: 18px;
            text-align: center;
            justify-content: center;
      }

      @include md {
            font-size: 16px;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include xl {
      }

      display: flex;
      @include font-face($font_type_5, $color_type_1, 0px);
      position: relative;
      flex-grow: 1;
      margin: 18px 0px 14px 0px;
      display: flex;
      justify-content: center;
}

//added by Jonathan 
.input1 {
      border: 0px;
      outline: none;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 35px;
      width: 105%;
      font-size: 18px;
      z-index: 9999;

}

//For chrome, Safari, Edge, Opera
.input1::-webkit-outer-spin-button,
.input1::-webkit-inner-spin-button {
      -webkit-appearance: none;
}

.input2 {
      border: 0px;
      outline: none;
      z-index: 9999;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 20px;
      width: 98%;
      min-height: 250px;
      font-size: 20px;
      resize: none;
      overflow: hidden;
      margin: 5px 10px;

      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }


}

textarea::placeholder {

      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_4, $color_type_3, 0px);
}