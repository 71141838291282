@use "utils" as *;

$color_type_0: rgb(133, 133, 133);
$color_type_1: rgb(33, 33, 33);
$color_type_2: rgb(45, 60, 31);

$font_type_0: 500 40px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_1: italic 600 22px/1.38 "Lato", Helvetica, Arial, serif;

.section1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {

      @include xxxl {
            margin: 240px auto 149px;
      }

      @include xxl {
            margin-bottom: 111px;
      }

      @include xl {
            margin: 190px auto 111px;
      }

      @include lg {
            width: 80.17%;
            margin: 140px auto 80px;
      }

      @include md {
            width: 84.35%;
            margin: 120px auto 60px;
      }

      @include sm {
            width: 87.79%;
            margin-bottom: 60px;
      }

      @include xs {
            width: 90.55%;
            margin-bottom: 37px;
      }

      @include xxs {
            width: 92.74%;
            margin-bottom: 25px;
      }

      @include tn {
            width: 94.46%;
      }

      @include flex-column;
      width: 75.2%;
      position: relative;
      flex-grow: 1;
      margin: 240px auto 299px;
}

.flexRow {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      @include xs {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
      }

      // display: flex;
      align-items: center;
      position: relative;
}

.flexRow__cell {
      @include xl {
            flex: 0 1 570px;
            min-width: unset;
      }

      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 3;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 645px;
}

.image {
      @include xl {
            margin: 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 1.72;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 7px 0px 0px;
      position: relative;
}


.flexRow__spacer {
      @include xl {
            flex: 0 1 71px;
            min-width: unset;
      }

      @include xs {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 104px;
}

.flexRow__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 1;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 388px;
}

.flexCol1 {
      @include xl {
            margin: 0px 0px 144px;
      }

      @include lg {
            margin: 0px 0px 127px;
      }

      @include md {
            margin: 0px 0px 85px;
      }

      @include sm {
            margin: 0px 0px 55px;
      }

      @include xs {
            margin: 0px 0px 14px;
      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 230px;
}

.flexRow1 {
      @include xs {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 0px 4px;
}

.flexRow1__cell {
      @include xs {
            flex: 0 1 49px;
            min-width: unset;
      }

      @include xxs {
            flex: 0 1 65px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 76px;
}

.line {
      background-color: $color_type_2;
      height: 1px;
      position: relative;
      margin: 13px 0px;
}

.flexRow1__cell1 {
      @include xs {
            flex: 0 1 20px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 32px;
}

.image3 {
      @include lg {
            margin: 0px 0px 0px 4px;
      }

      @include xs {
            width: 20px;
            min-width: 20px;
            margin: 0px 0px 0px 7px;
      }

      width: 24px;
      height: auto;
      aspect-ratio: 0.89;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 24px;
      margin: 0px 0px 0px 8px;
}



.hero_title {
      @include lg {
            font-size: 30px;
            text-align: left;
      }

      @include md {
            font-size: 28px;
      }

      @include sm {
            font-size: 22px;
      }

      @include xs {
            margin: 14px 0px 0px;
      }

      @include tn {
            font-size: 20px;
      }

      @include font-face($font_type_0, $color_type_1, 0px);
      position: relative;
      margin: 16px 0px 0px;
}

.subtitle {
      @include lg {
            font-size: 20px;
            text-align: left;
      }

      @include md {
            font-size: 16px;
      }

      @include sm {
            font-size: 12px;
      }

      @include xs {
            margin: 5px 4px 0px 0px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
      margin: 18px 4px 0px;
}

.flexCol2 {
      @include xl {
            margin: 50px 0px 0px;
      }

      @include xs {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
      }

      @include flex-column;
      position: relative;
      // margin: 77px 0px 0px;
}

.flexCol2__cell {
      @include xs {
            flex: 0 0 56px;
            min-height: unset;
      }

      @include flex-column;
      position: relative;
}

// 一二行海报样式
.poster {
      @include md {
            row-gap: 14px;
            column-gap: 14px;
      }

      @include sm {
            row-gap: 12px;
            column-gap: 12px;
      }

      @include xs {
            flex-wrap: wrap;
      }

      display: flex;
      position: relative;
      flex-grow: 1;
      justify-content: space-between;
      row-gap: 16px;
      column-gap: 16px;
}

.poster__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 20%;
}

.poster__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 40%;
}

.image4 {
      @include xs {
            margin: 0px;
      }

      width: 100%;
      height: 100%;
      aspect-ratio: 0.745;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.image14 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.49;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      // margin: 2px 0px 4px;
}

// 第三行海报样式

.poster1__cell {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 33.3%;
}

.poster1__image {
      width: 100%;
      height: auto;
      aspect-ratio: 0.71;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      // margin: 2px 0px 0px;
}

// 第四行海报样式
.poster3__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 30%;
}

.poster3__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 40%;
}

// 第五行海报样式
.poster2__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 25%;
}

.poster2__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: 50%;
}


.flexRow2 {
      @include xs {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      display: flex;
      align-items: center;
      column-gap: 12px;
      position: relative;
      flex-grow: 1;
}

.flexRow2__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 235px;
}


.flexRow2__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 437px;
}


.poster__cell2 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      width: calc(40% - 10px);
}


.image15 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.49;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}


.flexRow2__cell2 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 438px;
}

.flexRow2__cell7 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 438px;
}


.flexCol2__cell1 {
      @include flex-column;
      position: relative;
}

.flexRow3 {
      @include md {
            row-gap: 14px;
            column-gap: 14px;
      }

      @include sm {
            row-gap: 12px;
            column-gap: 12px;
      }

      @include xs {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      display: flex;
      align-items: center;
      column-gap: 17px;
      position: relative;
      flex-grow: 1;
      margin: 15px 0px 0px;
}

.flexRow3__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 448px;
}

.image5 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.39;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 0px 0px 1px;
}

.flexRow3__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 216px;
}

.image7 {
      width: 100%;
      height: auto;
      aspect-ratio: 0.67;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow3__cell2 {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 439px;
}

.image8 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.36;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow4 {
      @include md {
            row-gap: 14px;
            column-gap: 14px;
      }

      @include sm {
            row-gap: 12px;
            column-gap: 12px;
      }

      @include xs {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      display: flex;
      align-items: center;
      column-gap: 15px;
      position: relative;
      flex-grow: 1;
      margin: 14px 0px 0px 1px;
}

.flexRow4__cell {
      @include xs {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}


.flexRow4__cell5 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 370px;
}

.image16 {
      width: 100%;
      height: auto;
      aspect-ratio: 0.71;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 2px 0px 0px;
}


.image22 {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 2px 0px 0px 0px;
}

.image6 {
      width: 100%;
      height: auto;
      aspect-ratio: 0.7;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow4__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 384px;
}

.image9 {
      width: 100%;
      height: auto;
      aspect-ratio: 0.75;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow5 {
      @include md {
            row-gap: 14px;
            column-gap: 14px;
      }

      @include sm {
            row-gap: 12px;
            column-gap: 12px;
      }

      @include xs {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
      flex-grow: 1;
      margin: 19px 0px 0px;
      row-gap: 16px;
      column-gap: 16px;
}

.flexRow5__cell {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 320px;
}

.image10 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.24;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow5__spacer {
      @include xs {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 15px;
}

.flexRow5__cell1 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 349px;
}

.image11 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.35;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flexRow5__spacer1 {
      @include xs {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 23px;
}

.flexRow5__cell2 {
      @include xs {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 430px;
}

.image12 {
      width: 100%;
      height: auto;
      aspect-ratio: 1.656;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

a {
      text-decoration: none;
}