@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$font_type_0: 700 90px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 700 30px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_2: 400 22px/1.4 "Lato", Helvetica, Arial, serif;

video::-webkit-media-controls-enclosure{ 
      display: none;
  }
.section1 {
      @include xs {
            margin: 0px;
      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      /* @include xxl {
            width: 92.53%;
      } */

      /* @include xl {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
            width: 94.29%;
      } */

      @include xxl {
            margin: 251px auto 30px;
      }

      @include xl {
            margin: 221px auto 30px;
      }



       @include lg {
            // width: 92.51%;
            margin: 172px auto 30px;
      }

      @include md {
            // width: 92.22%;
            margin: 142px auto 30px;
      }

      @include sm {
            // width: 91.8%;
            margin: 132px auto 30px;
      }

      @include xs {
            // width: 93.72%;
            margin: 122px auto 15px;
      }

      /* @include xxs {
            width: 93.82%;
      } */

      @include tn {
            // width: 93.62%;
            margin: 112px auto 15px;
      }

      @include flex-column;
      width: 90.28%;
      position: relative;
      flex-grow: 1;
      margin: 277px auto 62px;
}

.flexCol__cell {
      @include xl {
            flex: 0 1 auto;
            min-height: unset;
            order: 1;
      }

      @include flex-column;
      position: relative;
}

.flexRow {
      @include md {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      flex-grow: 1;
      margin: 0px 2px;
}

.flexRow__cell {
      /* @include md {
            flex: 0 0 252px;
            min-width: 252px;
      }

      @include sm {
            flex: 0 0 199px;
            min-width: 199px;
      }

      @include xs {
            flex: 0 0 179px;
            min-width: 179px;
      }

      @include xxs {
            flex: 0 0 143px;
            min-width: 143px;
      }

      @include tn {
            flex: 0 0 122px;
            min-width: 122px;
      } */

      @include flex-column;
      position: relative;
      // flex: 0 0 298px;
      // min-width: 298px;
}

.title {
      @include lg {
            font-size: 24px;
            text-align: left;
      }

      @include sm {
            font-size: 20px;
      }

      @include xs {
            font-size: 18px;
      }

      @include xxs {
            font-size: 14px;
      }

      @include tn {
            font-size: 12px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
      // flex-grow: 1;
}

.flexRow__spacer {
      @include tn {
            flex: 0 1 6px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 26px;
}

.flexRow__cell1 {
      @include tn {
            flex: 0 1 107px;
            // min-width: unset;
      }

      // @include flex-column;
      // position: relative;
      flex: 0 1 114px;
}

.flexRow1 {
      @include xs {
            justify-content: flex-start;
            align-items: center;
            column-gap: 11px;
      }

      display: flex;
      align-items: center;
      column-gap: 11px;
      position: relative;
      flex-grow: 1;
      margin: 7px 0px 4px;
}

.flexRow1__cell {
      @include xs {
            flex: 0 1 56px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 75px;
}

.line1 {
      background-color: $color_type_0;
      height: 2px;
      position: relative;
      margin: 15px 0px 14px;
}

.flexRow1__cell1 {
      @include flex-column;
      position: relative;
      flex: 0 1 28px;
}

.image4 {
      @include xs {
            width: 20px;
            min-width: 20px;
            margin: 0px;
      }

      @include tn {
            width: 15px;
            min-width: 15px;
      }

      width: 28px;
      height: auto;
      aspect-ratio: 0.9;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 28px;
}

.flexCol__cell1 {
      @include xl {
            flex: 0 1 auto;
            min-height: unset;
            order: 2;
      }

      @include flex-column;
      position: relative;
}

.flexCol1 {
      @include xl {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
            width: 77.93%;
            margin: 21px 22.07% 0px 0%;
      }

      @include sm {
            width: 80.05%;
            margin: 23px 19.95% 0px 0%;
      }

      @include xxs {
            margin: 5px 19.95% 0px 0%;
      }

      @include flex-column;
      width: 71.14%;
      position: relative;
      flex-grow: 1;
      margin: 21px 28.86% 0px 0%;
}

.flexCol1__cell {
      @include xl {
            flex: 0 1 41px;
            min-height: unset;
      }

      @include flex-column;
      position: relative;
      z-index: 1;
}

.hero_title1 {
      @include xl {
            font-size: 79px;
            text-align: left;
            margin-right: 80px;
      }

      @include lg {
            font-size: 71px;
            margin-right: 60px;
      }

      @include md {
            font-size: 55px;
            margin: 0px;
            margin-right: 40px;
      }

      @include sm {
            font-size: 43px;
            margin-right: 40px;
      }

      @include xs {
            font-size: 36px;
            margin-right: 30px;
      }

      @include xxs {
            font-size: 29px;
            margin-right: 20px;
      }

      @include tn {
            font-size: 24px;
      }

      @include font-face($font_type_0, $color_type_1, 0px);
      position: relative;
      // flex-grow: 1;
      white-space: nowrap;
      margin-right: 100px;
}

.flexCol1__cell1 {
      margin-top: -353px;
      @include flex-column;
      position: relative;
      z-index: 0;
}

.icon {
      @include xl {
            width: 86px;
            height: 86px;
      }

      @include lg {
            width: 67px;
            height: 67px;
      }

      @include md {
            width: 44px;
            height: 44px;
      }

      @include sm {
            width: 47px;
            height: 47px;
      }

      @include xs {
            width: 32px;
            height: 32px;
      }

      @include xxs {
            width: 30px;
            height: 30px;
      }

      @include tn {
            width: 26px;
            height: 26px;
      }

      width: 90px;
      height: 90px;
}

.flexCol__cell2 {
      @include xl {
            flex: 0 1 auto;
            min-height: unset;
            order: 3;
      }

      @include md {
            flex: 0 0 auto;
      }

      @include flex-column;
      position: relative;
}

.flexRow2 {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
            margin: 120px 0px 0px 2px;
      }

      @include lg {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
            margin: 90px 0px 0px 2px;
      }

      @include md {
            margin: 60px 0px 0px 2px;
      }

      @include sm {
            margin: 30px 0px 0px 2px;
      }

      @include xs {
            margin: 15px 0px 0px 2px;
      }

      display: flex;
      align-items: center;
      position: relative;
      flex-grow: 1;
      margin: 150px 0px 0px 2px;
}

.flexRow2__cell {
      @include xl {
            min-width: unset;
      }

      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      @include flex-column;
      position: relative;
      flex: 1 1 378px;
}

.subtitle {
      @include xl {
            font-size: 20px;
            text-align: left;
      }

      @include md {
            font-size: 16px;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include lg {
            margin: 0px;
      }

      @include font-face($font_type_2, $color_type_1, 0px);
      position: relative;
      flex-grow: 1;
}

.flexRow2__spacer {
      @include lg {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 61px;
}

.flexRow2__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 924px;
}

.image {
      @include xl {
            margin: 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 1.9;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}

.flex_align {
      display: flex;
      align-items: center;
}