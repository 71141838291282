@use "utils" as *;

$color_type_0: rgb(252, 252, 252);
$color_type_1: rgb(91, 92, 57);
$color_type_2: rgb(92, 92, 92);

$border_type_0: 2px solid rgb(133, 133, 133);

$border_radius_type_0: 52px 52px 52px 52px;

$font_type_0: italic 600 48px/1 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.71 "Playfair Display", Helvetica, Arial, serif;
$font_type_2: 400 20px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: italic 600 1.1875em/1.33 "Playfair Display", Helvetica, Arial, serif;
$font_type_4: 600 1.1875em/1.33 "Playfair Display", Helvetica, Arial, serif;
$font_type_5: 0.22916666666666666em/5.9 "Playfair Display", Helvetica, Arial, serif;



$padding_type_0: undefinedpx undefinedpx undefinedpx undefinedpx;


.main {
      @include flex-column;
      background-color: $color_type_0;
      padding: $padding_type_0;
      position: relative;
      overflow: hidden;
}

.flexCol {
      padding: 0px 40%;
      padding-bottom: 120px;


      @include xxxl {
            padding: 0px 40%;
            padding-bottom: 120px;
      }

      @include xl {
            padding: 0px 35%;
            padding-bottom: 120px;

      }

      @include lg {
            padding: 0px 35%;
            padding-bottom: 120px;

      }

      @include md {
            padding: 0px 35%;

      }


      @include xs {
            padding: 0px 30%;
      }


      @include tn {
            padding-bottom: 0px;


      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 287px auto 175px;
      z-index: 0;
}

.content_box {

      @include flex-column;
      border: $border_type_0;
      border-radius: $border_radius_type_0;
      outline-style: none;
      outline-width: 0px;
      position: relative;
      min-height: 56px;
      max-height: 69px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition-duration: 0.3s;

}

.content_box:hover {
      transform: scale(1.1);
}

.medium_title {


      justify-content: center;
      display: flex;
      align-items: center;
      @include font-face($font_type_1, $color_type_2, 0px);
      width: 97.96%;
      position: relative;
      flex-grow: 1;
      min-height: 44px;
      max-height: 44px;
}

.payment input {
      border: 1px solid rgb(118, 118, 118);
      border-radius: 10px;
      outline: none;
      padding: 10px;
}

.payment label{
      margin: 10px 0;
}


.payment input::-webkit-outer-spin-button,
.payment input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;

}

#amount {
      margin-bottom: 500px;
      padding-bottom: 100px;
}

/* Firefox */
input[type=number] {
      -moz-appearance: textfield;
}

.gap {
      content: "";
      height: 20px;
      width: 100px;
}
.country_select{
      height: 40px;
      border-radius: 10px;
      padding-left: 5px;
      outline: none;
}