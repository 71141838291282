@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$border_type_0: 1px solid rgb(92, 92, 92);

$border_radius_type_0: 26.5px 26.5px 26.5px 26.5px;

$font_type_0: 600 96px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 700 30px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_2: 700 22px/1.38 "Lato", Helvetica, Arial, serif;
$font_type_3: 400 20px/1.55 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_4: 600 20px/1.55 "Lato", Helvetica, Arial, serif;
$font_type_5: 20px/1.35 "Lato", Helvetica, Arial, serif;
$font_type_6: 400 20px/1.35 "Lato", Helvetica, Arial, serif;

.section2 {
      @include lg {
            margin: 0px 0px 0px 1px;
      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include xl {
            width: 93.8%;
            margin: 76px 2.77% 0 3.43%;
      }

      @include lg {
            width: 93.26%;
            margin: 76px 3.04% 0 3.7%;
      }

      @include xs {
            margin: 26px 3.04% 0 3.7%;
      }

      @include xxs {
            margin: 26px 3.04% 0 3.7%;
      }

      @include flex-column;
      width: 86.09%;
      position: relative;
      flex-grow: 1;
      margin: 76px 10.17% 0 3.75%;
}

.flexCol1 {
      @include lg {
            margin: 0px 20px 0px 0px;
      }

      @include md {
            margin: 0px 16px 0px 0px;
      }

      @include sm {
            margin: 0px 12px 0px 0px;
      }

      @include tn {
            margin: 0px 8px 0px 0px;
      }

      @include flex-column;
      position: relative;
      margin: 0px 24px 0px 0px;
}

.flexRow {
      @include md {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 0px 2px;
}

.flexRow__cell {
      @include md {
            flex: 0 0 102px;
            min-width: 102px;
      }

      @include xs {
            flex: 0 0 77px;
            min-width: 77px;
      }

      @include xxs {
            flex: 0 0 61px;
            min-width: 61px;
      }

      @include tn {
            flex: 0 0 51px;
            min-width: 51px;
      }

      @include flex-column;
      position: relative;
      flex: 0 0 125px;
      min-width: 125px;
}

.title {
      @include lg {
            font-size: 24px;
            text-align: left;
      }

      @include sm {
            font-size: 20px;
      }

      @include xs {
            font-size: 18px;
      }

      @include xxs {
            font-size: 14px;
      }

      @include tn {
            font-size: 12px;
            margin: 0px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
      // flex-grow: 1;
      white-space: nowrap;
}

.flexRow__spacer {
      @include md {
            flex: 0 1 17px;
            min-width: unset;
      }

      @include tn {
            flex: 0 1 8px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 26px;
}

.flexRow__cell1 {
      @include xxs {
            flex: 0 1 52px;
            min-width: unset;
      }

      @include tn {
            flex: 0 1 37px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 75px;
}

.line2 {
      @include sm {
            margin: 20px 0px 17px;
      }

      @include xs {
            aspect-ratio: 38.109999999999964;
      }

      @include tn {
            margin: 17px 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 37.5;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 23px 0px 17px;
}

.flexRow__spacer1 {
      @include tn {
            flex: 0 1 5px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 11px;
}

.flexRow__cell2 {
      @include tn {
            flex: 0 1 12px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 28px;
}

.image4 {
      @include sm {
            margin: 7px 0px 3px;
      }

      @include xs {
            width: 18px;
            min-width: 18px;
      }

      @include tn {
            width: 15px;
            min-width: 15px;
            margin: 3px 0px;
      }

      width: 28px;
      height: auto;
      aspect-ratio: 0.9;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 28px;
      margin: 8px 0px 3px;
}

.flexRow1 {
      @include lg {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 12px 0px 0px;
}

.flexRow1__cell {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 0 557px;
      min-width: 557px;
}

.hero_title {
      @include lg {
            font-size: 80px;
            text-align: left;
            margin: 0px;
      }

      @include md {
            font-size: 68px;
      }

      @include sm {
            font-size: 56px;
      }

      @include xs {
            font-size: 48px;
      }

      @include xxs {
            font-size: 40px;
      }

      @include font-face($font_type_0, $color_type_1, 0px);
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 9px;
}

.flexRow1__spacer {
      @include lg {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 190px;
}

.flexRow1__cell1 {
      @include lg {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 516px;
}

.subtitle1 {
      @include lg {
            text-align: left;
            margin: 0px;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_3, $color_type_1, 0px);
      position: relative;
      flex-grow: 1;
      margin: 49px 0px 0px;
}

.flexRow2 {
      @include xl {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            margin: 109px 0px 0px;
      }

      @include lg {
            margin: 43px 0px 0px;
      }

      @include xs {
            margin: 27px 0px 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 139px 0px 0px;
}

.flexRow2__cell {
      @include xl {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 1 1 540px;
}

.flexCol2 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.rect {
      @include xl {
            min-height: 2px;
      }

      @include md {
            margin: 0px 12px 0px 0px;
      }

      @include sm {
            margin: 0px 8px 0px 0px;
      }

      @include xxs {
            min-height: 1px;
      }

      background-color: $color_type_1;
      position: relative;
      min-height: 4px;
      margin: 0px 18px 0px 0px;
}

.subtitle2 {
      @include md {
            font-size: 16px;
            text-align: left;
      }

      @include xxs {
            font-size: 14px;
      }

      @include sm {
            margin: 29px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      margin: 24px 0px 0px;
}

.subtitle3 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include font-face($font_type_5, $color_type_1, 0px);
      width: 100%;
      height: 100%;
}

.flexRow2__spacer {
      @include xl {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 207px;
}

.flexCol3 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 4px;
}

.rect1 {
      @include xl {
            min-height: 2px;
      }

      @include md {
            margin: 25px 12px 0px 0px;
      }

      @include sm {
            max-height: 2px;
            margin: 25px 8px 0px 0px;
      }

      @include xxs {
            min-height: 1px;
            max-height: 1px;
      }

      background-color: $color_type_1;
      position: relative;
      min-height: 4px;
      margin: 25px 18px 0px 0px;
}

.subtitle21 {
      @include md {
            font-size: 16px;
            text-align: left;
      }

      @include xxs {
            font-size: 14px;
      }

      @include sm {
            margin: 32px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      margin: 24px 0px 0px;
}

.subtitle31 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include sm {
            font-size: 14px;
      }

      @include xs {
            font-size: 12px;
      }

      @include md {
            min-height: 126px;
      }

      @include font-face($font_type_6, $color_type_1, 0px);
      position: relative;
      min-height: 185px;
      margin: 11px 0px 0px;
}

.content_box {
      @include flex-column;
      border: $border_type_0;
      border-radius: $border_radius_type_0;
      outline: 1px none rgb(92, 92, 92);
      outline-offset: -1px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition-duration: 0.3s;
      display: flex;
      align-items: center;
}

.content_box:hover {
      transform: translateX(-8px);
      background-color: #5B5C39;

      .image2 {
            filter: invert(500%);
            filter: brightness(10);
      }

      .subtitle4 {
            color: white;
      }
}

.flexRow3 {
      @include lg {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
            margin: 7px 16px;
      }

      @include md {
            margin: 7px 17px 7px 18px;
      }

      @include sm {
            margin: 0px 14px 5px 12px;
      }

      @include xxs {
            margin: 0px 8px 5px 12px;
      }

      @include tn {
            margin: 0px 8px 5px;
      }

      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      flex-grow: 1;
      margin: 12px 21px 7px 16px;
}

.flexRow3__cell {

      @include flex-column;
      position: relative;
      margin-right: 20px;
}

.subtitle4 {
      @include lg {
            font-size: 16px;
      }

      @include sm {
            font-size: 14px;
            margin: 0px 0px 0px 5px;
      }

      @include xs {
            font-size: 12px;
      }

      font: $font_type_4;
      color: $color_type_1;
      text-align: justify;
      letter-spacing: 0px;
      position: relative;
      flex-grow: 1;
      white-space: nowrap;
}

.flexRow3__cell1 {
      margin-left: -2px;
      @include flex-column;
      position: relative;
      flex: 0 1 31px;
}

.image2 {
      width: 31px;
      height: auto;
      aspect-ratio: 3.88;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 31px;
      margin: 13px 0px;
}