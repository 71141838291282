@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(45, 60, 31);
$font_type_0: 500 40px/1.38 "Lato", Helvetica, Arial, serif;

.section1 {
    @include flex-column;
    position: relative;
    flex-grow: 1;
}

.flexCol {

    @include xxxl {
        margin: 240px auto 68px;
    }


    @include xl {
        margin: 190px auto 68px;
    }

    @include lg {
        width: 80.17%;
        margin: 150px auto 68px;
    }

    @include md {
        width: 84.35%;
    }

    @include sm {
        width: 87.79%;
    }

    @include xs {
        width: 90.55%;
    }

    @include xxs {
        width: 92.74%;
    }

    @include tn {
        width: 94.46%;
    }

    @include flex-column;
    width: 75.2%;
    position: relative;
    flex-grow: 1;
    margin: 120px auto 68px;
}

.flexCol1 {


    @include lg {
        width: 80.17%;
    }

    @include md {
        width: 84.35%;
    }

    @include sm {
        width: 87.79%;
    }

    @include xs {
        width: 90.55%;
    }

    @include xxs {
        width: 92.74%;
    }

    @include tn {
        width: 94.46%;
    }

    @include flex-column;
    width: 75.2%;
    position: relative;
    flex-grow: 1;
    margin: 0 auto;
}

.wab_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.wab_title .name {
    @include lg {
        font-size: 24px;
        text-align: left;
    }

    @include sm {
        font-size: 20px;
    }

    @include xs {
        font-size: 18px;
    }

    @include xxs {
        font-size: 14px;
    }

    @include tn {
        font-size: 12px;
    }

    @include font-face($font_type_0, $color_type_0, 0px);
    position: relative;
}

.wab_title .line1 {
    @include lg {
        width: 75px;
        text-align: left;
        margin: 0 15px 0px 30px;
    }

    @include sm {
        width: 56px;
        margin: 0 10px 0px 20px;
    }

    @include xs {
        width: 40px;
    }

    @include xxs {
        width: 30px;
    }

    background-color: $color_type_0;
    height: 2px;
    width: 75px;
    position: relative;
    margin: 0 15px 0px 30px;
}

.wab_title .image1 {
    @include xs {
        width: 20px;
        min-width: 20px;
        margin: 0px;
    }

    @include tn {
        width: 15px;
        min-width: 15px;
    }

    width: 28px;
    height: auto;
    aspect-ratio: 0.9;
    vertical-align: top;
    object-fit: cover;
    object-position: center center;
    position: relative;
    min-width: 28px;
}

.cover_box {
    aspect-ratio: 1.6 / 1;
    // background-image: url(../assets/Branding/wabisabi/cover.jpg);
    // background-size: cover;
}

.cover_box .image_cover {
    width: 100%;
    height: 100%;
}

.street {
    @include xl {
        margin-top: 80px;
    }

    @include lg {
        margin-top: 60px;
    }

    @include md {
        margin-top: 60px;
    }

    @include sm {
        margin-top: 40px;
    }

    @include xs {
        margin-top: 20px;
    }

    @include xxs {
        margin-top: 20px;
    }

    margin-top: 80px;
}

.street .image_street {
    width: 100%;

}

.line2 {
    @include sm {
        margin-top: 0px;
    }

    @include xs {
        margin-top: 50px;
    }

    @include xxs {
        margin-top: 50px;
    }

    height: 3px;
    width: 96px;
    background-color: rgb(91, 92, 57);
    margin-bottom: 20px;
}

.selected {
    @include xs {
        margin-top: 60px;
    }

    margin-top: 100px;
}

.selected_title {
    @include xs {
        margin-bottom: 30px;
        font-size: 30px;
    }

    @include font-face($font_type_0, $color_type_0, 0px);
    margin-bottom: 50px;

    span {
        border-bottom: 2px solid rgb(91, 92, 57);
    }
}

.selected_title1 {
    @include xs {
        font-size: 30px;
    }

    @include font-face($font_type_0, $color_type_0, 0px);

    span {
        border-bottom: 2px solid rgb(91, 92, 57);
    }
}

.flexbet {
    @include xs {
        margin-bottom: 30px;
        font-size: 30px;
    }

    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        @include xs {
            display: block;
        }

        width: 30px;
        height: 30px;
        display: none;
    }
}

.selected_box {
    @include xs {
        display: none;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.selected_mobile {
    @include xs {
        display: block;
    }

    display: none;

    div {
        width: 100%;
        aspect-ratio: 1.5 / 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .photographs_left {
        background-image: url("../assets/Branding/wabisabi/1.jpg");
    }

    .photographs_right {
        background-image: url("../assets/Branding/wabisabi/2.jpg");
    }

    img {
        width: 100%;
    }
}


.selected_box div {
    width: 31%;
    margin-bottom: 5%;
}

.selected_box img {
    width: 100%;
}

.arrow_box {
    display: flex;
    justify-content: flex-end;
    height: 50px;
}

.arrow {
    @include font-face($font_type_0, $color_type_1, 0px);
    margin-left: 20px;
}

.photographs_box {
    @include xs {
        display: none;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.footer .center {
    height: 100%;
    display: flex;
    align-items: center;
}

.footer .center1 {
    @include md {
        height: 333px;
    }

    @include sm {
        height: 250px;
    }

    @include xs {
        height: 100%;
    }

    height: 100%;
    display: flex;
    align-items: center;
}

.footer img {
    width: 100%;
}

.footer .line3 {
    height: 3px;
    width: 96px;
    background-color: rgb(91, 92, 57);
}

.footer {
    @include xxl {
        margin-bottom: 171px;
    }

    @include xl {
        margin-bottom: 171px;
    }

    @include lg {
        margin-bottom: 118px;
    }


    @include sm {
        margin-bottom: 118px;
    }

    @include xs {}

    @include xxs {
        margin-bottom: 25px;
    }

    margin-bottom: 299px;
    margin-top: 50px;

    h3 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .top_right {
        margin-top: 20px;
    }

    .two_photo {
        @include xs {
            margin-top: 50px;
        }
    }
}