@use "utils" as *;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(92, 92, 92);

$font_type_0: 700 42px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 22px/1.4 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_2: 20px/1.35 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_3: 700 100px/1.38 "Playfair Display", Helvetica, Arial, serif;

.section3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include xl {
    margin: 54px auto 171px;
  }
  @include lg {
    width: 81.18%;
    margin: 54px auto 118px;
  }
  @include md {
    width: 85.19%;
    margin: 20px auto 118px;
  }
  @include sm {
    width: 88.46%;
    margin: 0px auto 118px;
  }
  @include xs {
    width: 91.09%;
    margin: 0px auto 37px;
  }
  @include xxs {
    width: 93.16%;
    margin: 0px auto 25px;
  }
  @include tn {
    width: 94.78%;
  }
  @include flex-column;
  width: 76.39%;
  position: relative;
  flex-grow: 1;
  margin: 109px auto 299px;
}
.flexRow {
  @include sm {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  display: flex;
  align-items: center;
  position: relative;
}
.flexRow__cell {
  @include sm {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 473px;
}
.image1 {
  width: 100%;
  height: auto;
  aspect-ratio: 0.96;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image1:hover {
  transform: scale(1.1);
}
.flexRow__spacer {
  @include sm {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 126px;
}
.flexRow__cell1 {
  @include sm {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
//   position: relative;
  flex: 0 1 556px;
}
.flexCol1 {
  @include sm {
    margin: 0px 0px 52px;
  }
  @include xs {
    margin: 18px 0px 45px;
  }
  @include xxs {
    margin: 18px 0px 69px;
  }
  @include tn {
    margin: 0px 0px 59px;
  }
  @include flex-column;
//   position: relative;
  // flex-grow: 1;
  margin: 83px 0px 114px;
}
.hero_title1 {
  @include xl {
    font-size: 38px;
    text-align: left;
  }
  @include lg {
    font-size: 32px;
  }
  @include md {
    font-size: 28px;
  }
  @include sm {
    font-size: 24px;
  }
  @include xxs {
    font-size: 22px;
  }
  @include tn {
    font-size: 18px;
  }
  @include font-face($font_type_0, $color_type_0, 0px);
//   position: relative;
}
.flexCol2 {
  @include lg {
    margin: 10px 0px 0px;
  }
  @include tn {
    margin: 0px;
  }
  // @include flex-column;
  position: relative;
  margin: 22px 0px 0px;
}
.hero_title2 {
  @include xl {
    font-size: 95px;
    text-align: left;
  }
  @include lg {
    font-size: 77px;
  }
  @include md {
    font-size: 70px;
  }
  @include sm {
    font-size: 48px;
  }
  @include xs {
    font-size: 52px;
    margin: 0px 0px 19px;
  }
  @include xxs {
    font-size: 48px;
  }
  @include tn {
    font-size: 52px;
  }
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
}
.flexCol2__cell {
  margin-top: -111px;
  @include flex-column;
  position: relative;
}
.subtitle1 {
  @include xl {
    font-size: 18px;
    text-align: left;
    width: 82.66%;
    margin: 12px 0% 0px 17.34%;
  }
  @include lg {
    font-size: 16px;
    width: 83.95%;
    margin: 25px 0% 0px 16.05%;
  }
  @include md {
    font-size: 13px;
    width: 82.47%;
    margin: 38px 0% 0px 17.53%;
  }
  @include sm {
    font-size: 12px;
    width: 69.48%;
    margin: 63px 22.26% 0px 8.25%;
  }
  @include xs {
    width: 58.87%;
    margin: 39px 30.93% 0px 10.2%;
  }
  @include xxs {
    width: 64.71%;
    margin: 35px 24.09% 0px 11.2%;
  }
  @include tn {
    width: 86.03%;
    margin: 37px 0% 0px 13.97%;
  }
  @include font-face($font_type_1, $color_type_1, 0px);
  width: 85.07%;
  position: relative;
  flex-grow: 1;
  margin: 0px 0% 0px 14.93%;
}
.flexCol2__cell1 {
  margin-top: -1px;
  @include flex-column;
  position: relative;
}
.subtitle11 {
  @include xl {
    font-size: 18px;
    text-align: left;
    margin: 3px 0% 0px 1.97%;
  }
  @include lg {
    font-size: 16px;
    margin: 2px 0% 0px 1.97%;
  }
  @include md {
    font-size: 13px;
    margin: 1px 0% 0px 1.97%;
  }
  @include sm {
    font-size: 12px;
    width: 79.78%;
    margin: 2px 18.62% 0px 1.61%;
  }
  @include xs {
    width: 69.03%;
    margin: 1px 30.97% 0px 0%;
  }
  @include xxs {
    width: 74.29%;
    margin: 1px 25.71% 0px 0%;
  }
  @include tn {
    width: 100%;
    margin: 1px 0px 0px;
  }
  @include font-face($font_type_1, $color_type_1, 0px);
  width: 98.03%;
  position: relative;
  flex-grow: 1;
  margin: 0px 0% 0px 1.97%;
}
.flexRow1 {
  @include lg {
    margin: 40px 0px 0px;
  }
  @include md {
    margin: 0px;
  }
  @include sm {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 93px 0px 0px;
}
.flexRow1__cell {
  @include sm {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 3;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 545px;
}
.flexCol3 {
  @include sm {
    margin: 0px 0px 55px;
  }
  @include xs {
    margin: 11px 0px 55px;
  }
  @include xxs {
    margin: 0px 0px 55px;
  }
  @include flex-column;
  position: relative;
  // flex-grow: 1;
  margin: 104px 0px 129px;
}
.hero_title11 {
  @include xl {
    font-size: 38px;
    text-align: left;
    width: 83.05%;
    margin: 0px 16.95% 0px 0%;
  }
  @include lg {
    font-size: 32px;
  }
  @include md {
    font-size: 28px;
  }
  @include sm {
    font-size: 24px;
  }
  @include xs {
    width: 68.37%;
    // margin: 0px 0% 0px 31.63%;
  }
  @include xxs {
    font-size: 22px;
    width: 81.13%;
    // margin: 0px 0% 0px 18.87%;
  }
  @include tn {
    font-size: 18px;
    width: 100%;
    margin: 0px 0px;
  }
  @include font-face($font_type_0, $color_type_0, 0px);
  width: 71.01%;
  position: relative;
  // margin: 0px auto;
}
.flexCol4 {
  @include flex-column;
  position: relative;
}
.vision{
  @include xl {
    font-size: 18px;
  }
  @include lg {
    font-size: 16px;
  }
  @include md {
    font-size: 13px;
  }
  @include sm {
    font-size: 12px;
  }
  @include font-face($font_type_2, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
}
// 行高1.35
.vision strong{
  @include xl {
    font-size: 72px;
    margin-right: 10px;
  }
  @include lg {
    font-size: 64px;
    margin-right: 8px;
  }
  @include md {
    font-size: 52px;
    margin-right: 6px;
  }
  @include sm {
    font-size: 32px;
    margin-right: 4px;
  }
  float: left;
  @include font-face($font_type_3, $color_type_1, 0px);
  font-size: 81px;
  line-height: 1;
  margin-right: 10px;
}

.flexCol4__cell {
  @include flex-column;
  position: relative;
  z-index: 2;
}
.hero_title21 {
  @include xl {
    font-size: 95px;
    text-align: left;
    margin: 0px;
  }
  @include lg {
    font-size: 77px;
  }
  @include md {
    font-size: 67px;
  }
  @include sm {
    font-size: 48px;
    margin: 0px 0px 15px;
  }
  @include xs {
    font-size: 52px;
    margin: 0px 0px 15px 138px;
  }
  @include xxs {
    font-size: 48px;
    margin: 0px 0px 0px 68px;
  }
  @include tn {
    font-size: 52px;
    margin: 0px 0px 14px 4px;
  }
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
}
.flexCol4__cell1 {
  margin-top: -104px;
  @include flex-column;
  position: relative;
  z-index: 0;
}
.subtitle2 {
  @include xl {
    font-size: 18px;
    text-align: left;
    width: 82.17%;
    margin: 8px 0% 0px 17.83%;
  }
  @include lg {
    font-size: 16px;
    width: 84.12%;
    margin: 22px 0% 0px 15.88%;
  }
  @include md {
    font-size: 13px;
    width: 83.36%;
    margin: 33px 0% 0px 16.64%;
  }
  @include sm {
    font-size: 12px;
    width: 75.95%;
    margin: 43px 15.49% 0px 8.57%;
  }
  @include xs {
    width: 59.03%;
    margin: 33px 0% 0px 40.97%;
  }
  @include xxs {
    width: 69.43%;
    margin: 48px 0% 0px 30.57%;
  }
  @include tn {
    width: 84.59%;
    margin: 32px 0% 0px 15.41%;
  }
  @include font-face($font_type_2, $color_type_1, 0px);
  width: 84.59%;
  position: relative;
  flex-grow: 1;
  margin: 0px 0% 0px 15.41%;
}
.flexCol4__cell2 {
  @include flex-column;
  position: relative;
  z-index: 1;
}
.subtitle21 {
  @include xl {
    font-size: 18px;
    text-align: left;
    margin: 2px 0px 0px;
  }
  @include lg {
    font-size: 16px;
    margin: 0px 0px;
  }
  @include md {
    font-size: 13px;
  }
  @include sm {
    font-size: 12px;
    width: 85.17%;
    margin: 0px 14.83% 0px 0%;
  }
  @include xs {
    width: 67.87%;
    margin: 0px 0% 0px 32.13%;
  }
  @include xxs {
    width: 80.38%;
    margin: 0px 0% 0px 19.62%;
  }
  @include tn {
    width: 100%;
    margin: 0px 0px;
  }
  @include font-face($font_type_2, $color_type_1, 0px);
  width: 100%;
  position: relative;
  flex-grow: 1;
  margin: 4px 0px 0px;
}
.flexRow1__spacer {
  @include sm {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 101px;
}
.flexRow1__cell1 {
  @include sm {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 1;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 473px;
}
.image11 {
  width: 100%;
  height: auto;
  aspect-ratio: 0.96;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image11:hover {
  transform: scale(1.1);
}
