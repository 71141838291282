@use "utils" as *;

$color_type_0: rgb(252, 252, 252);

$padding_type_0: undefinedpx undefinedpx undefinedpx undefinedpx;

.main {
  @include flex-column;
  background-color: $color_type_0;
  padding: $padding_type_0;
  position: relative;
  overflow: hidden;
}
.main__item {
  @include flex-column;
  position: relative;
}
.section4 {
  position: relative;
}
