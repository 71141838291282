@use "utils" as *;

$color_type_0: rgb(92, 92, 92);

$font_type_0: 600 42px/1.38 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 800 24px/1 "Lato", Helvetica, Arial, serif;
$font_type_2: 400 18px/1.47 "Lato", Helvetica, Arial, serif;
$font_type_3: 18px/1.47 "Lato", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include xxl {
        margin-bottom: 171px;
  }
  @include xl {
    margin: 83px auto 0px;
    margin-bottom: 171px;
  }
  @include lg {
    width: 80.05%;
    margin: 26px auto 118px;
  }
  @include md {
    width: 84.25%;
  }
  @include sm {
    width: 87.7%;
    margin-bottom: 118px;
  }
  @include xs {
    width: 90.48%;
    margin-bottom: 37px;
  }
  @include xxs {
    width: 92.69%;
    margin-bottom: 25px;
  }
  @include tn {
    width: 94.41%;
  }
  @include flex-column;
  width: 75.07%;
  position: relative;
  flex-grow: 1;
  margin: 83px auto 299px;
}
.flexRow {
  @include lg {
    justify-content: flex-start;
    align-items: center;
    column-gap: 0px;
  }
  display: flex;
  align-items: center;
  position: relative;
}
.flexRow__cell {
  @include lg {
    margin-right: 40px;
  }
  @include md {
    margin-right: 30px;
  }
  @include sm {
    margin-right: 20px;
  }
  @include xs {
    margin-right: 10px;
  }
  @include flex-column;
  position: relative;
  margin-right: 40px;
}
.hero_title {
  @include xl {
    font-size: 40px;
    text-align: left;
    justify-content: flex-start;
  }
  @include lg {
    font-size: 30px;
  }
  @include md {
    font-size: 26px;
  }
  @include sm {
    font-size: 22px;
  }
  @include xs {
    font-size: 20px;
  }
  @include xxs {
    font-size: 16px;
  }
  display: flex;
  justify-content: center;
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex-grow: 1;
}
.hero_Subheading{
  white-space: nowrap;
  padding: 5px;
  margin-right: 50px;
  @include lg {
    font-size: 16px;
    margin-right: 40px;
  }
  @include md {
    font-size: 14px;
    margin-right: 30px;
  }
  @include sm {
    font-size: 12px;
    margin-right: 20px;
  }
  @include xs {
    margin-right: 10px;
  }
}
.flexRow__spacer {
  @include md {
    flex: 0 1 14px;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 30px;
}
.flexRow__cell1 {
  @include flex-column;
  position: relative;
  flex: 0 1 75px;
}
.line2 {
  width: 100%;
  height: auto;
  aspect-ratio: 37.5;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  margin: 30px 0px 27px;
}
.flexRow__spacer1 {
  @include lg {
    min-width: unset;
  }
  @include sm {
    flex: 0 1 5px;
    min-width: unset;
  }
  @include xxs {
    flex: 0 1 5px;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 11px;
}
.flexRow__cell2 {
  @include flex-column;
  position: relative;
  flex: 0 1 28px;
}
.flexRow__cell3{
  display: flex;
  align-items: center;
}
.image2 {
  @include md {
    width: 24px;
    min-width: 24px;
  }
  @include xs {
    width: 20px;
    min-width: 20px;
  }
  @include xxs {
    width: 18px;
    min-width: 18px;
  }
  width: 28px;
  height: auto;
  aspect-ratio: 0.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 28px;
  margin: 15px 0px 13px;
}
.flexRow1 {
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
    margin: 37px 0px 0px 5px;
  }
  @include lg {
    margin: 24px 0px 0px 5px;
  }
  @include xs {
    margin: 0px 0px 0px 5px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 79px 0px 0px 5px;
}
.flexRow1__cell {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 533px;
  margin-bottom: 10px;
}
.image3 {
  width: 100%;
  height: auto;
  aspect-ratio: 1.65;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image3:hover {
  transform: translateX(-8px);
}
.flexRow1__spacer {
  @include xl {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 67px;
}
.flexRow1__cell1 {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 530px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.medium_title {
  @include lg {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  @include xs {
    font-size: 14px;
  }
  @include font-face($font_type_1, $color_type_0, 0px);
  position: relative;
}
.highlights {
  @include md {
    font-size: 14px;
    text-align: left;
    margin: 18px 0px 0px;
  }
  @include sm {
    font-size: 12px;
  }
  @include xs {
    margin: 15px 0px 0px;
  }
  @include xxs {
    margin: 9px 0px 0;
  }
  @include font-face($font_type_2, $color_type_0, 0px);
  position: relative;
  margin: 26px 0px 0px;
}
.flexRow2 {
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
    margin: 30px 2px 0px 5px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 90px 2px 0px 5px;
}
.flexRow2__cell {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 3;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 530px;
}
.medium_title1 {
  @include lg {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  @include xs {
    font-size: 14px;
    min-height: 24px;
    margin: 0px 8px 5px 0px;
  }
  @include md {
    margin: 0px 12px 0px 0px;
  }
  @include xxs {
    margin: 0px 8px 0px 0px;
  }
  @include font-face($font_type_1, $color_type_0, 0px);
  position: relative;
  min-height: 31px;
  margin: 0px 19px 0px 0px;
}
.highlights1 {
  @include md {
    font-size: 14px;
    text-align: left;
    margin: 12px 0px 0px;
  }
  @include sm {
    font-size: 12px;
  }
  @include xs {
    margin: 0px;
  }
  @include font-face($font_type_3, $color_type_0, 0px);
  position: relative;
  margin: 16px 0px 0px;
}
.flexRow2__spacer {
  @include xl {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 65px;
}
.flexRow2__cell1 {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    order: 1;
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 533px;
}
.image31 {
  @include xl {
    margin: 0px 0px 20px;
  }
  @include sm {
    margin: 0px 0px 4px;
  }
  @include xs {
    margin: 0px 0px 7px;
  }
  width: 100%;
  height: auto;
  aspect-ratio: 1.65;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  margin: 67px 0px 81px;
}
.image31:hover {
  transform: translateX(8px);
}
.flexRow3 {
  @include xxl{
    margin: 150px 0px 0px 5px;
  }
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
    margin: 80px 0px 0px 5px;
  }
  @include xs {
    margin: 40px 0px 0px 5px;
  }
  display: flex;
  align-items: center;
  position: relative;
  margin: 200px 0px 0px 5px;
}
.flexRow3__cell {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 533px;
  margin-bottom: 10px;
}
.image32 {
  width: 100%;
  height: auto;
  aspect-ratio: 1.5;
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
}
.image32:hover {
  transform: translateX(-8px);
}
.flexRow3__spacer {
  @include xl {
    display: none;
  }
  @include flex-column;
  position: relative;
  flex: 0 1 67px;
}
.flexRow3__cell1 {
  @include xl {
    flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
    min-width: unset;
    // margin-bottom: 100px;
  }
  @include sm{
    // margin-bottom: 50px;
  }
  @include flex-column;
  position: relative;
  flex: 1 1 530px;
}
.highlights2 {
  @include md {
    font-size: 14px;
    text-align: left;
    margin: 15px 0px 0px;
  }
  @include sm {
    font-size: 12px;
    margin: 11px 0px 0px;
  }
  @include lg {
    margin: 18px 0px 0px;
  }
  @include font-face($font_type_2, $color_type_0, 0px);
  position: relative;
  margin: 26px 0px 0px;
}
