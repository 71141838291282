@use "utils" as *;

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgb(45, 60, 31);

$border_type_0: 1px solid rgb(255, 255, 255);

$border_radius_type_0: 23px;

$font_type_0: 500 28px/1.46 "Montserrat", Helvetica, Arial, serif;
$font_type_1: 600 16px/1.12 "Montserrat", Helvetica, Arial, serif;
$font_type_2: 400 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type_3: 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type_4: 600 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type_5: 500 14px/1.28 "Montserrat", Helvetica, Arial, serif;

.root {
      @include flex-column;
      background-color: $color_type_1;
      position: relative;
}

.flexCol {
      @include xxl {
            width: 92.73%;
      }

      @include xl {
            width: 94.45%;
      }

      @include lg {
            width: 95.78%;
      }

      @include md {
            width: 96.8%;
      }

      @include sm {
            width: 97.58%;
            margin: 105px auto 50px;
      }

      @include xs {
            width: 98.17%;
      }

      @include xxs {
            width: 96.88%;
            margin: 105px 0.66% 50px 2.46%;
      }

      @include tn {
            width: 97.64%;
            margin: 105px 0.5% 50px 1.86%;
      }

      @include flex-column;
      width: 90.54%;
      position: relative;
      flex-grow: 1;
      margin: 105px auto 65px;
}

.flexCol__cell {
      @include flex-column;
      position: relative;
      flex: 0 1 81px;
}

.flexRow {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
            width: 90.49%;
            margin: 0px 4.19% 0px 5.32%;
      }

      @include lg {
            width: 92.7%;
            margin: 0px 3.22% 0px 4.09%;
      }

      @include md {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
            width: 92.31%;
            margin: 0px 2.4% 0px 5.29%;
      }

      @include sm {
            width: 92.8%;
            margin: 0px 1.81% 0px 5.39%;
      }

      @include xs {
            width: 93.33%;
            margin: 0px 1.37% 0px 5.3%;
      }

      @include xxs {
            width: 93.87%;
            margin: 0px 1.03% 0px 5.1%;
      }

      @include tn {
            width: 93.58%;
            margin: 0px 0.77% 0px 5.65%;
      }

      display: flex;
      align-items: center;
      width: 93.79%;
      position: relative;
      flex-grow: 1;
      margin: 0px 0% 0px 6.21%;
}

.flexRow__cell {
      @include xl {
            flex: 0 1 388px;
            min-width: unset;
      }

      @include md {
            flex: 0 0 274px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 345px;
}

.image {
      @include xl {
            margin: 13px 0px 18px;
      }

      @include lg {
            margin: 13px 0px 10px;
      }

      @include xs {
            margin: 13px 0px 15px;
      }

      @include xxs {
            width: calc(100% - 39px);
            margin: 13px 39px 15px 0px;
      }

      @include tn {
            width: calc(100% - 68px);
            margin: 13px 68px 15px 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 5.79;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 13px 0px 11px;
}

.flexRow__spacer {
      @include xl {
            flex: 0 1 323px;
            min-width: unset;
      }

      @include lg {
            flex: 0 1 317px;
      }

      @include md {
            flex: 1 1 calc(100% - 274px);
            margin-left: 0px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 305px;
}

.flexRow__cell1 {
      @include xl {
            flex: 0 1 712px;
            min-width: unset;
      }

      @include lg {
            flex: 0 1 482px;
      }

      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      @include flex-column;
      position: relative;
      flex: 0 1 439px;
}

.title {
      @include xl {
            font-size: 22px;
            text-align: left;
            // margin: 0px 69px 0px 0px;
      }

      @include lg {
            margin: 0px;
      }

      @include md {
            font-size: 20px;
      }

      @include sm {
            font-size: 16px;
      }

      @include xs {
            font-size: 14px;
            // margin: 0px 174px 0px 0px;
      }

      @include tn {
            font-size: 13px;
            // margin: 0px 32px 0px 0px;
      }

      @include xxs {
            // margin: 0px 92px 0px 0px;
      }

      @include font-face($font_type_0, $color_type_0, 0px);
      position: relative;
      flex-grow: 1;
}

.flexRow1 {
      @include xl {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
            width: 90.01%;
            margin: 64px 4.44% 0px 5.55%;
      }

      @include md {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
            width: 90.21%;
            margin: 64px 4.45% 0px 5.34%;
      }

      display: flex;
      align-items: center;
      width: 88.82%;
      position: relative;
      margin: 64px 4.38% 0px 6.79%;
}

.flexRow1__cell {
      @include xl {
            flex: 0 1 274px;
            min-width: unset;
      }

      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      @include flex-column;
      position: relative;
      flex: 0 1 273px;
}

.flexCol1 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 1px 0px 46px;
}

.highlights {
      @include lg {
            font-size: 12px;
            text-align: left;
      }

      @include md {
            font-size: 14px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
      margin: 0px 0px 0px 2px;
}

.content_box {
      @include flex-column;
      border: $border_type_0;
      border-radius: $border_radius_type_0;
      outline: 1px none rgb(255, 255, 255);
      outline-offset: -1px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition-duration: 0.3s;
      transition-property: transform;
      justify-content: center;
}

.content_box:hover {
      transform: scale(1.1);
}

.highlights1 {
      @include lg {
            // text-align: left;
            // margin: 14px 20px 14px 20px;
      }

      @include md {
            font-size: 18px;
            text-align: center;
            justify-content: center;
            // margin: 11px 10px 14px 11px;
      }

      @include xxs {
            font-size: 16px;
      }

      @include tn {
            font-size: 14px;
      }

      @include sm {
            // margin: 11px 10px 14px 11px;
      padding: 11px 0;
      }

      @include xs {
            // margin: 11px 12px 14px;
      }

      display: flex;
      @include font-face($font_type_1, $color_type_0, 0px);
      cursor: pointer;
      position: relative;
      flex-grow: 1;
      // margin: 14px 21px 14px 21px;
      padding: 14px 0;
      justify-content: center;
      text-align: center;
}

.flexRow1__spacer {
      @include xl {
            flex: 0 1 236px;
            min-width: unset;
      }

      @include lg {
            flex: 0 1 103px;
      }

      @include md {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 374px;
}

.flexRow1__cell1 {
      @include lg {
            flex: 0 1 500px;
            min-width: unset;
      }

      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
      }

      // @include flex-column;
      position: relative;
      flex: 0 1 501px;
}

.flexRow2 {
      @include md {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
      // flex-grow: 1;
}

.flexRow2__cell {
      @include md {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 117px;
}

.flexCol2 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin-bottom: 1px;
}

.highlights2 {
      @include lg {
            font-size: 12px;
            text-align: left;
      }

      @include md {
            font-size: 14px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
}

.highlights2:hover {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
}

.text {
      @include md {
            margin: 20px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      cursor: pointer;
      position: relative;
      margin: 17px 0px 0px;
}

.flexCol2__cell {
      @include flex-column;
      position: relative;
      flex: 0 1 28px;
}

.text1 {
      @include font-face($font_type_3, $color_type_0, 0px);
      cursor: pointer;
      position: relative;
      flex-grow: 1;
      margin: 10px 0px 0px;
}

.text2 {
      @include font-face($font_type_2, $color_type_0, 0px);
      cursor: pointer;
      position: relative;
      flex-grow: 1;
      margin: 10px 0px 0px;
}

.text3 {
      @include lg {
            margin: 10px 16px 0px 0px;
      }

      @include sm {
            margin: 10px 12px 0px 0px;
      }

      @include xxs {
            margin: 10px 8px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      cursor: pointer;
      position: relative;
      margin: 10px 21px 0px 0px;
}

.flexRow2__spacer {
      @include md {
            flex: 0 0 16px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 82px;
}

.flexRow2__cell1 {
      @include md {
            flex: 0 0 calc(1 / 2 * 100% - 16px / 2);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 69px;
}

.flexCol3 {
      @include flex-column;
      position: relative;
      // flex-grow: 1;
      margin: 0px 0px 56px;
}

.highlights3 {
      @include lg {
            font-size: 12px;
            text-align: left;
      }

      @include md {
            font-size: 14px;
      }

      @include font-face($font_type_1, $color_type_0, 0px);
      position: relative;
      margin: 0px 7px 0px 0px;
}

.highlights3:hover {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
}

.text4 {
      @include md {
            margin: 20px 8px 0px 0px;
      }

      @include xxs {
            margin: 20px 4px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      margin: 17px 14px 0px 0px;
}

.text5 {
      @include lg {
            margin: 17px 4px 0px 0px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      margin: 17px 8px 0px 0px;
      cursor: pointer;
}

.text6 {
      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      margin: 28px 0px 0px;
      cursor: pointer;
      color: transparent;
      font-size: 0px;
}

.flexRow2__spacer1 {
      @include md {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 74px;
}

.flexRow2__cell2 {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 159px;
}

.flexCol4 {
      @include flex-column;
      position: relative;
      // flex-grow: 1;
      margin: 0px 0px 56px;
}

.text21 {
      @include md {
            margin: 28px 0px 0px;
      }

      @include sm {
            margin: 31px 0px 0px;
      }

      @include xs {
            margin: 37px 0px 0px;
      }

      @include tn {
            margin: 39px 0px 0px;
      }

      @include font-face($font_type_4, $color_type_0, 0px);
      position: relative;
}

.text21:hover {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
}

.flexRow3 {
      @include md {
            margin: 20px 0px 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 17px 0px 0px;
}

.flexRow3__cell {
      @include flex-column;
      position: relative;
      flex: 0 1 13px;
}

.image1 {
      width: 13px;
      height: auto;
      aspect-ratio: 1.3;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 13px;
      margin: 4px 0px;
}

.flexRow3__cell1 {
      @include flex-column;
      position: relative;
      flex: 0 0 146px;
      min-width: 146px;
}

.text7 {
      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 0px 7px;
}

.flexRow4 {
      @include v-center-content;
      position: relative;
      margin: 10px 1px 0px;
}

.flexRow4__cell {
      @include flex-column;
      position: relative;
      flex: 0 1 11px;
}

.icon {
      width: 11px;
      height: auto;
      aspect-ratio: 1;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 11px;
      margin: 3px 0px 4px;
}

.flexRow4__cell1 {
      @include flex-column;
      position: relative;
      flex: 0 0 105px;
      min-width: 105px;
}

.text8 {
      @include lg {
            margin: 0px 0px 0px 4px;
      }

      @include font-face($font_type_2, $color_type_0, 0px);
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 0px 8px;
      white-space: nowrap;
}

.line {
      @include md {
            margin: 0px 40px;
      }

      @include xxs {
            margin: 0px 20px;
      }

      background-color: $color_type_0;
      height: 1px;
      position: relative;
      margin: 47px 4px 0px 0px;
}

.text11 {
      @include xxs {
            font-size: 12px;
            margin: 33px 16px 0px 0px;
      }

      @include lg {
            margin: 38px 0px 0px;
      }

      @include md {
            margin: 38px 40px 0px 0px;
      }

      @include sm {
            margin: 42px 40px 0px 0px;
      }

      @include xs {
            margin: 33px 40px 0px 0px;
      }

      display: flex;
      justify-content: flex-end;
      font: $font_type_5;
      color: $color_type_0;
      text-align: right;
      letter-spacing: 0px;
      position: relative;
      margin: 54px 0px 0px;
}
