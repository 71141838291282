@use "utils" as *;

$color_type_0: #5b5c39ff;
$color_type_1: rgb(252, 252, 252);
$color_type_2: rgb(92, 92, 92);

$font_type_0: 700 17px/1.23 "Lato", Helvetica, Arial, serif;

.root {
      @include flex-column;
      background-color: $color_type_1;
      position: relative;
}

.flexRow {
      /* @include xxl {
            width: 92.37%;
      } */

      @include xl {
            align-items: stretch;
            column-gap: 0px;
            // width: 94.17%;
      }

      /* @include lg {
            width: 95.56%;
      } */

      @include md {
            // width: 96.12%;
            margin: 38px 1.68% 28px 2.2%;
      }

      @include sm {
            // width: 97.07%;
            margin: 38px 1.27% 28px 1.66%;
      }

      @include xs {
            // width: 97.79%;
            margin: 38px 0.96% 28px 1.25%;
      }

      @include xxs {
            // width: 98.33%;
            margin: 38px 0.72% 28px 0.94%;
      }

      @include tn {
            // width: 98.74%;
            margin: 38px 0.54% 28px 0.71%;
      }

      display: flex;
      width: 90.28%;
      position: relative;
      flex-grow: 1;
      margin: 38px auto 58px;
      justify-content: space-between;
}

.flexRow__cell {
      @include md {
            flex: 0 1 297px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 301px;
}

.image {
      @include xl {
            width: calc(100% - 197px);
            margin: 0px 123px -221px 0px;
      }

      @include lg {
            width: calc(100% - 99px);
            margin: 0px 99px -221px 0px;
      }

      @include md {
            width: calc(100% - 60px);
            margin: 0px 52px -221px 8px;
      }

      width: calc(100% - 197px);
      height: auto;
      aspect-ratio: 1.01;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 15px 197px -221px 0px;
      cursor: pointer;

}

.flexRow__spacer {
      @include md {
            flex: 0 1 457px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 468px;
}

.flexRow__cell1 {

      @include flex-column;
      position: relative;
      text-align: center;
}

.flexRow__cell1 h5{
      padding: 0 5px 10px;
}

.flexRow__cell1 h5:hover {
      box-shadow:0 3px 0 0 rgb(92, 92, 92);
      ;
}

.flexRow__cell2 {

      @include flex-column;
      width: 250px;
}

.flexRow1 {
      @include xl {
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 0px;
            margin: 41px 0px 20px;
      }

      @include lg {
            margin: 39px 0px 20px;
      }

      display: flex;
      position: relative;
      margin: 58px 0px 20px;
}

.flexRow1__cell {
      @include flex-column;
      position: relative;
}

.highlights {
      @include md {
            font-size: 12px;
            text-align: left;
      }

      @include font-face($font_type_0, $color_type_2, 0px);
      cursor: pointer;
      transition-duration: 0.3s;
      transition-property: color;
      position: relative;
      flex-grow: 1;
      min-height: 21px;
}

.highlights:hover {
      color: $color_type_0;
}

.flexRow1__spacer {
      @include xl {
            flex: 0 1 51px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 57px;
}

.flexRow1__cell1 {
      @include flex-column;
      position: relative;
}

.flexRow1__spacer1 {
      @include xxl{
            width:40px;
      }
      @include xl{
            width:30px;
      }
      @include flex-column;
      position: relative;
      width:57px;
}
.flexRow1__spacer2 {
      @include xxl{
            flex: 0 1 30px;
      }
      @include flex-column;
      position: relative;
      flex: 0 1 57px;
}

.flexRow1__cell2 {
      @include flex-column;
      position: relative;
}

.flexRow1__cell3 {
      @include flex-column;
      position: relative;
}

.flexRow1__cell4 {
      @include flex-column;
      position: relative;
}

.changebtn{
      display: flex;
      align-items: center;
}
.icon {
      width: 25px;
      height: 25px;
      aspect-ratio: 1;
      background: var(--src) center center / cover no-repeat;
      position: relative;
      margin-right: 5px;
    }
.language_text{
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 20px;
color: #5C5C5C;
}

.contact {
      background-color: #5B5C39;
      box-sizing: content-box;
      border: 1px solid rgb(92, 92, 92);
      border-radius: 26.5px 26.5px 26.5px 26.5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @include md {
            text-align: left;
      }

      width: 80px;
      @include font-face($font_type_0, white, 0px);
      font-size: 14px;
      // flex-grow: 1;
      min-height: 21px;
}