@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

*,
*::before,
*::after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
figure {
      display: block;
      font-size: 1em;
      font-weight: normal;
      margin: 0;
      border-width: 0;
      opacity: 1;
}

ul {
      display: block;
      margin: 0;
      padding: 0;
}

li {
      display: block;
}

button {
      background-color: transparent;
      border: none;
      display: block;
      padding: 0;
      margin: 0;
      align-items: normal;
}

body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
}

code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
}

/* 横图片 */
.watermark {
      position: relative;
}


.watermark::after {
      content: '';
      position: absolute;
      bottom: 3%;
      right: 4%;
      width: 10%;
      aspect-ratio: 1;
      background: url("./assets/logo.svg") no-repeat center center;
      background-size: 100% 100%;
      /* background-color: skyblue; */
}

/* .watermark::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 666;
} */

.watermark1 {
      position: relative;
}

.watermark1::after {
      content: '';
      position: absolute;
      bottom: 15%;
      right: 4%;
      width: 10%;
      aspect-ratio: 1;
      background: url("./assets/logo.svg") no-repeat center center;
      background-size: 100% 100%;
}

.watermark2 {
      position: relative;
}

.watermark2::after {
      content: '';
      position: absolute;
      bottom: 3%;
      right: 20%;
      width: 10%;
      aspect-ratio: 1;
      background: url("./assets/logo.svg") no-repeat center center;
      background-size: 100% 100%;
}

/* 竖图片 */
.watermark3 {
      position: relative;
}

.watermark3::after {
      content: '';
      position: absolute;
      bottom: 3%;
      right: 4%;
      width: 15%;
      aspect-ratio: 1;
      background: url("./assets/logo.svg") no-repeat center center;
      background-size: 100% 100%;
}

.page-header {
      z-index: 100;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
}


video::-webkit-media-controls {
      display: none !important;
}

video::-webkit-media-controls-enclosure {
      display: none !important;
}

video::-webkit-media-controls-panel {
      display: none !important;
}

video::-webkit-media-slider-container {
      display: none !important;
}

video::-webkit-media-slider {
      display: none !important;
}



@media (max-width: 99999px) {
      .max\:show {
            display: flex;
            flex-direction: column;
      }

      .xxxl\:show {
            display: none;
      }

      .xxl\:show {
            display: none;
      }

      .xl\:show {
            display: none;
      }

      .lg\:show {
            display: none;
      }

      .md\:show {
            display: none;
      }

      .sm\:show {
            display: none;
      }

      .xs\:show {
            display: none;
      }

      .max\:hide {
            display: none;
      }
}

@media (max-width: 2999px) {
      .xxxl\:show {
            display: flex;
      }

      .xxxl\:hide {
            display: none;
      }
}

@media (max-width: 1919px) {
      .xxl\:show {
            display: flex;
      }

      .xxl\:hide {
            display: none;
      }
}

@media (max-width: 1399px) {
      .xl\:show {
            display: flex;
      }

      .xl\:hide {
            display: none;
      }

      .navbar-toggler,
      .navbar-toggler.collapsed {
            visibility: hidden;
      }

      .container-fluid svg {
            color: #5B5C39;
            position: absolute;
            right: 50px;
            height: 35px;
            width: 35px;
            cursor: pointer;
      }
}

@media (max-width: 1199px) {
      .lg\:show {
            display: flex;
      }

      .lg\:hide {
            display: none;
      }
}

@media (max-width: 991px) {
      .md\:show {
            display: flex;
      }

      .md\:hide {
            display: none;
      }
}

@media (max-width: 767px) {
      .sm\:show {
            display: flex;
      }

      .sm\:hide {
            display: none;
      }
}

@media (max-width: 575px) {
      .xs\:show {
            display: flex;
      }

      .xs\:hide {
            display: none;
      }
}


.material-symbols-outlined {
      font-family: 'Material Symbols Outlined';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      /* -webkit-font-feature-settings: 'liga'; */
      -webkit-font-smoothing: antialiased;
}


img {
      pointer-events: none !important;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}


a {
      text-decoration: none;
}