@use "utils" as *;

// $color_type_0: rgb(91, 92, 57);
// $color_type_1: rgb(92, 92, 92);

// $font_type_0: 700 90px/1.38 "Playfair Display", Helvetica, Arial, serif;
// $font_type_1: 700 30px/1.38 "Lato", Helvetica, Arial, serif;
// $font_type_2: 400 22px/1.4 "Lato", Helvetica, Arial, serif;

$color_type_0: rgb(91, 92, 57);
$color_type_1: rgb(45, 60, 31);
$font_type_0: 500 40px/1.38 "Lato", Helvetica, Arial, serif;

.flexbet {
    @include xs {
        margin-bottom: 30px;
        font-size: 30px;
    }

    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        @include xs {
            display: block;
        }

        width: 30px;
        height: 30px;
        display: none;
    }
}

.selected_title {
    @include xs {
        font-size: 30px;
    }

    @include font-face($font_type_0, $color_type_0, 0px);

    span {
        border-bottom: 2px solid rgb(91, 92, 57);
    }
}

.image{
    width: 100%;
}
.image2{
    width: 100%;
    margin-top: 40px;
}

.star{
    @include xs {
        display: none;
    }
    height: 100%;
    display: flex;
    align-items: flex-end;
    img{
        margin-left: 30px;
    }
}
.poster_image{
    width: 100%;
    height: 100%;
}
.bottom_img{
    
    @include xxl {
        margin-bottom: 171px;
  }

  @include xl {
        margin-bottom: 171px;
  }
  @include lg {
        margin-bottom: 118px;
  }

  @include xs {
        margin-bottom: 37px;
  }

  @include xxs {
        margin-bottom: 25px;
  }
    margin-top: 30px;
    margin-bottom: 299px;
}