@use "utils" as *;

$color_type_0: rgb(133, 133, 133);
$color_type_1: rgb(91, 92, 57);
$color_type_2: rgb(92, 92, 92);

$border_type_0: 1px solid rgb(92, 92, 92);

$border_radius_type_0: 26.5px 26.5px 26.5px 26.5px;

$font_type_0: 600 64px/1.36 "Playfair Display", Helvetica, Arial, serif;
$font_type_1: 600 30px/1.38 "Source Sans Pro", Helvetica, Arial, serif;
$font_type_2: 600 20px/1.55 "Lato", Helvetica, Arial, serif;
$font_type_3: 600 20px/0.9 "Montserrat", Helvetica, Arial, serif;
$font_type_4: 400 16px/1.18 "Source Sans Pro", Helvetica, Arial, serif;

.section3 {
      @include lg {
            margin: 0px 0px 0px 4px;
      }

      @include xxs {
            margin: 0px;
      }

      @include flex-column;
      position: relative;
      flex-grow: 1;
}

.flexCol {
      @include xxl {
            width: 92.53%;
            margin-bottom: 171px;
      }

      @include xl {
            width: 94.11%;
            margin: 100px auto 171px;
      }

      @include lg {
            width: 92.88%;
            margin: 100px auto 118px;
      }

      @include md {
            width: 92.28%;
            margin:80px auto;
            margin-bottom: 118px;
      }

      @include sm {
            width: 91.8%;
            margin:50px auto;
            margin-bottom: 118px;
      }

      @include xs {
            width: 93.72%;
            margin-bottom: 37px;
      }

      @include xxs {
            width: 92.75%;
            margin: 17px auto 25px;
      }

      @include tn {
            width: 93.35%;
            margin: 17px 3.13% 79px 3.52%;
      }

      @include flex-column;
      width: 90.28%;
      position: relative;
      flex-grow: 1;
      margin: 100px auto 299px;
}

.flexCol1 {
      @include flex-column;
      position: relative;
}

.flexRow {
      @include sm {
            justify-content: flex-start;
            align-items: center;
            column-gap: 11px;
      }

      display: flex;
      align-items: center;
      column-gap: 11px;
      position: relative;
}

.flexRow__cell {
      @include flex-column;
      position: relative;
}

.title1 {
      @include lg {
            font-size: 24px;
            text-align: left;
      }

      @include sm {
            font-size: 20px;
      }

      @include xxs {
            font-size: 16px;
      }

      @include font-face($font_type_1, $color_type_1, 0px);
      position: relative;
      // flex-grow: 1;
      margin-right: 15px;
}

.flexRow__cell1 {
      @include xxs {
            flex: 0 1 50px;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 75px;
}

.line1 {
      background-color: $color_type_1;
      height: 2px;
      position: relative;
      margin: 22px 0px 18px;
}

.flexRow__cell2 {
      @include flex-column;
      position: relative;
      flex: 0 1 28px;
}

.image4 {
      @include xs {
            width: 18px;
            min-width: 18px;
      }

      width: 28px;
      height: auto;
      aspect-ratio: 0.9;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 28px;
      margin: 7px 0px 4px;
}

.hero_title2 {
      @include lg {
            font-size: 56px;
            text-align: left;
      }

      @include md {
            font-size: 42px;
            width: 100%;
            margin: 26px 0px 0px;
      }

      @include sm {
            font-size: 30px;
            margin: 15px 0px 0px;
      }

      @include xs {
            font-size: 25px;
      }

      @include xxs {
            font-size: 20px;
      }

      @include tn {
            margin: 6px 0px 0px;
      }

      @include font-face($font_type_0, $color_type_2, 0px);
      width: 77.8%;
      position: relative;
      margin: 26px 22.2% 0px 0%;
      max-width: 1200px;
}

.flexRow1 {
      @include lg {
            justify-content: flex-start;
            align-items: center;
            column-gap: 0px;
      }

      @include md {
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 16px;
            margin: 33px 0px 0px 2px;
      }

      @include xs {
            margin: 19px 0px 0px 2px;
      }

      @include xxs {
            margin: 19px 0px 0px;
      }

      display: flex;
      align-items: center;
      position: relative;
      margin: 72px 0px 0px 2px;
}

.flexRow1__cell {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 3;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 361px;
}

.flexCol2 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 0px 0px 12px;
}

.flexRow2 {
      @include md {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
            margin: 0px;
      }

      display: flex;
      align-items: center;
      column-gap: 15px;
      position: relative;
}

.flexRow2__cell {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 51px;
}

.icon1 {
      @include md {
            width: 40px;
            min-width: 40px;
            margin: 0px 0px 0px 330px;
      }

      @include sm {
            margin: 0px 0px 0px 241px;
      }

      @include xs {
            margin: 0px 0px 0px 202px;
      }

      @include xxs {
            margin: 0px 0px 0px 157px;
      }

      @include tn {
            width: 32px;
            min-width: 32px;
            margin: 0px 0px 0px 131px;
      }

      width: 51px;
      height: auto;
      aspect-ratio: 1;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 51px;
      margin: 0px 0px 112px;
}

.flexRow2__cell1 {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 291px;
}

.flexCol3 {
      @include flex-column;
      position: relative;
      flex-grow: 1;
      margin: 1px 0px 0px;
}

.subtitle5 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include md {
            text-align: center;
            justify-content: center;
      }

      @include xs {
            font-size: 14px;
      }

      display: flex;
      @include font-face($font_type_3, $color_type_2, 0px);
      position: relative;
}

.highlights {
      @include lg {
            font-size: 12px;
            text-align: left;
      }

      @include md {
            text-align: center;
            justify-content: center;
      }

      @include xs {
            margin: 10px 8px 0px;
      }

      display: flex;
      @include font-face($font_type_4, $color_type_0, 0px);
      position: relative;
      margin: 10px 0px 0px;
}

.flexRow3 {
      @include lg {
            margin: 43px 0px 0px;
      }

      @include md {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            row-gap: 16px;
            column-gap: 16px;
      }

      @include tn {
            margin: 30px 0px 0px;
      }

      display: flex;
      align-items: center;
      column-gap: 15px;
      position: relative;
      margin: 52px 0px 0px;
}

.flexRow3__cell {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 51px;
}

.icon11 {
      @include md {
            width: 40px;
            min-width: 40px;
            margin: 0px 0px 0px 330px;
      }

      @include sm {
            margin: 0px 0px 0px 241px;
      }

      @include xs {
            margin: 0px 0px 0px 202px;
      }

      @include xxs {
            margin: 0px 0px 0px 157px;
      }

      @include tn {
            width: 32px;
            min-width: 32px;
            margin: 0px 0px 0px 131px;
      }

      width: 51px;
      height: auto;
      aspect-ratio: 1;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 51px;
      margin: 0px 0px 97px;
}

.flexRow3__cell1 {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 295px;
}

.subtitle51 {
      @include lg {
            font-size: 16px;
            text-align: left;
      }

      @include md {
            text-align: center;
            justify-content: center;
      }

      @include xs {
            font-size: 15px;
      }

      display: flex;
      @include font-face($font_type_3, $color_type_2, 0px);
      position: relative;
}

.highlights1 {
      @include lg {
            font-size: 12px;
            text-align: left;
      }

      @include md {
            text-align: center;
            justify-content: center;
            min-height: 97px;
      }

      @include xs {
            margin: 10px 8px 0px;
      }

      display: flex;
      @include font-face($font_type_4, $color_type_0, 0px);
      position: relative;
      min-height: 119px;
      margin: 10px 0px 0px;
}

.flexRow1__spacer {
      @include lg {
            flex: 0 0 52px;
            min-width: unset;
      }

      @include md {
            display: none;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 104px;
}

.flexRow1__cell1 {
      @include md {
            flex: 0 0 calc(1 / 1 * 100% - 0px / 1);
            order: 1;
            min-width: unset;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 898px;
}

.image1 {
      @include md {
            margin: 2px 0px 36px;
      }

      @include tn {
            margin: 2px 0px 13px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 2.41;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      margin: 2px 0px 0px;
}

.content_box1 {
      @include flex-column;
      border: $border_type_0;
      border-radius: $border_radius_type_0;
      outline: 1px none rgb(92, 92, 92);
      outline-offset: -1px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition-duration: 0.3s;
      display: flex;
      align-items: center;

}

.content_box1:hover {
      transform: translateX(-8px);
      background-color: #5B5C39;

      .subtitle4 {
            color: white;
      }

      .image2 {
            filter: invert(500%);
            filter: brightness(10);
      }
}

.flexRow4 {
      @include lg {
            justify-content: flex-start;
            align-items: center;
            column-gap: 14px;
            margin: 7px 23px 6px 21px;
      }

      @include md {
            margin: 7px 16px 6px;
      }

      @include sm {
            margin: 2px 12px 0px 13px;
      }

      @include xs {
            column-gap: 5px;
      }

      @include tn {
            margin: 2px 8px 0px 13px;
      }

      display: flex;
      align-items: center;
      column-gap: 14px;
      cursor: pointer;
      position: relative;
      flex-grow: 1;
      margin: 12px 23px 10px 21px;
}

.flexRow4__cell {
      @include flex-column;
      position: relative;
}

.subtitle4 {
      @include lg {
            font-size: 16px;
      }

      @include sm {
            font-size: 14px;
            margin: 0px;
      }

      @include xs {
            font-size: 12px;
      }

      font: $font_type_2;
      color: $color_type_2;
      text-align: justify;
      letter-spacing: 0px;
      position: relative;
      flex-grow: 1;
      white-space: nowrap;
}

.flexRow4__cell1 {
      @include sm {
            flex: 0 1 40px;
            min-width: unset;
      }

      @include xs {
            flex: 0 1 14px;
      }

      @include flex-column;
      position: relative;
      flex: 0 1 31px;
}

.image2 {
      @include sm {
            margin: 9px 0px 10px;
      }

      @include xs {
            width: 20px;
            min-width: 20px;
      }

      width: 31px;
      height: auto;
      aspect-ratio: 3.88;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
      min-width: 31px;
      margin: 13px 0px 10px;
}

.image {
      @include xl {
            margin: 0px;
      }

      width: 100%;
      height: auto;
      aspect-ratio: 1.9;
      vertical-align: top;
      object-fit: cover;
      object-position: center center;
      position: relative;
}